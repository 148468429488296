"use client";
import React from "react";
import { Button } from "./MovingBorder";

const SecMovingButton = ()=> {

    console.log("box is showin");
  return (
    <div className=" flex flex-col gap-5 ">
        
        <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        Advanced AI Technology
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        AI-based registration and attendance tracking
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        Automated exam answer sheet evaluations
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
       AI-based lunchbox calorie analysis
      </Button>
     
    </div>
  );
}
export default SecMovingButton;

