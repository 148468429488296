

import React, { useState } from 'react';
import { CircularProgress, Container, TextField, Button, Typography, MenuItem, Grid, Box } from '@mui/material';
import toast from "react-hot-toast";

const FacultyEnrollment = ({ schoolId, selectedPage }) => {
    const [userType, setUserType] = useState('faculty');
    const [fullName, setFullName] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [assignments, setAssignments] = useState([{ standard: '', section: '' }]); // Array to hold multiple standard-section pairs
    const [assignSubject, setAssignSubject] = useState('');
    const [classTeacher, setClassTeacher] = useState('');
    const [classTeacherDetails, setClassTeacherDetails] = useState([{ standard: '', section: '' }]);
    const [loader, setLoader] = useState(false);

    const submitHandler = async (event) => {
        event.preventDefault();
        setLoader(true);

        if (password !== confirmPassword) {
            toast.error('Password and Confirm Password do not match');
            setLoader(false); // Reset loader state
            return; // Prevent form submission
        }

        const facultyData = {
            userType,
            fullName,
            aadhaarNumber,
            address,
            email,
            password,
            confirmPassword,
            assignments,
            assignSubject,
            classTeacher,
            classTeacherDetails,
            schoolId
        };

        console.log(facultyData);

        try {
            const response = await fetch('https://api.isnartecherp.online/api/v1/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(facultyData),
            });

            if (response.ok) {
                // Reset form data after successful submission             
                setFullName('');
                setAadhaarNumber('');
                setAddress('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setAssignments([{ standard: '', section: '' }]); // Reset assignments to default
                setAssignSubject('');
                setClassTeacher('');
                setClassTeacherDetails([{ standard: '', section: '' }]);

                // Show success toast
                toast.success('Form submitted successfully');
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            // Show error toast
            toast.error('Failed to submit form');
            console.error('Error submitting form:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', backgroundColor: '#3f51b5', borderRadius: '8px' }}>
                    <Typography
                        variant="h5"
                        align="center"
                        gutterBottom
                        style={{
                            fontWeight: 500,
                            color: '#fff',
                            padding: '8px 0'
                        }}
                    >
                        Enroll Faculty
                    </Typography>
                </Box>
                <form onSubmit={submitHandler} style={{ width: '100%', marginTop: 16 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="User Type"
                                fullWidth
                                value={userType}
                                onChange={(event) => setUserType(event.target.value)}
                                disabled // Disable editing
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Full Name"
                                fullWidth
                                value={fullName}
                                onChange={(event) => setFullName(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Aadhaar Number"
                                fullWidth
                                value={aadhaarNumber}
                                onChange={(event) => setAadhaarNumber(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                fullWidth
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                fullWidth
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Confirm Password"
                                fullWidth
                                type="password"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h8" sx={{ marginBottom: 12 }} gutterBottom>
                                Assign Standards and Sections
                            </Typography>
                            {assignments.map((assignment, index) => (
                                <Grid container spacing={2} key={index} sx={{ mb: 1 }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Standard"
                                            fullWidth
                                            value={assignment.standard}
                                            onChange={(event) => {
                                                const updatedAssignments = [...assignments];
                                                updatedAssignments[index].standard = event.target.value;
                                                setAssignments(updatedAssignments);
                                            }}
                                            required
                                        >
                                            {['Nursery', 'L.K.G', 'U.K.G', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            select
                                            label="Section"
                                            fullWidth
                                            value={assignment.section}
                                            onChange={(event) => {
                                                const updatedAssignments = [...assignments];
                                                updatedAssignments[index].section = event.target.value;
                                                setAssignments(updatedAssignments);
                                            }}
                                            required
                                        >
                                            {['A', 'B', 'C', 'D'].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {index > 0 && (
                                        <Grid item xs={12}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button
                                                    onClick={() => {
                                                        const updatedAssignments = [...assignments];
                                                        updatedAssignments.splice(index, 1);
                                                        setAssignments(updatedAssignments);
                                                    }}
                                                    variant="outlined"
                                                    color="secondary"
                                                    sx={{ mt: 1 }}
                                                >
                                                    Remove
                                                </Button>
                                                <Button
                                                    onClick={() => setAssignments([...assignments, { standard: '', section: '' }])}
                                                    variant="outlined"
                                                    sx={{ mt: 1 }}
                                                >
                                                    Assign More
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                            {assignments.length === 1 && (
                                <Button
                                    onClick={() => setAssignments([...assignments, { standard: '', section: '' }])}
                                    variant="outlined"
                                    sx={{ mt: 2 }}
                                >
                                    Assign More
                                </Button>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Assign Subject"
                                fullWidth
                                value={assignSubject}
                                onChange={(event) => setAssignSubject(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Class Teacher"
                                fullWidth
                                value={classTeacher}
                                onChange={(event) => setClassTeacher(event.target.value)}
                                required
                            >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                            </TextField>
                        </Grid>
                        {classTeacher === 'Yes' && (
                            <Grid item xs={12}>
                                <Typography variant="h8" gutterBottom>
                                    Class Teacher Details
                                </Typography>
                                {classTeacherDetails.map((detail, index) => (
                                    <Grid container spacing={2} key={index}>
                                        <Grid item xs={6}>
                                            <TextField
                                                select
                                                label="Standard"
                                                fullWidth
                                                value={detail.standard}
                                                onChange={(event) => {
                                                    const updatedDetails = [...classTeacherDetails];
                                                    updatedDetails[index].standard = event.target.value;
                                                    setClassTeacherDetails(updatedDetails);
                                                }}
                                                required
                                            >
                                                {['Nursery', 'L.K.G', 'U.K.G', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                select
                                                label="Section"
                                                fullWidth
                                                value={detail.section}
                                                onChange={(event) => {
                                                    const updatedDetails = [...classTeacherDetails];
                                                    updatedDetails[index].section = event.target.value;
                                                    setClassTeacherDetails(updatedDetails);
                                                }}
                                                required
                                            >
                                                {['A', 'B', 'C', 'D'].map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {index > 0 && (
                                            <Grid item xs={12}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <Button
                                                        onClick={() => {
                                                            const updatedDetails = [...classTeacherDetails];
                                                            updatedDetails.splice(index, 1);
                                                            setClassTeacherDetails(updatedDetails);
                                                        }}
                                                        variant="outlined"
                                                        color="secondary"
                                                        sx={{ mt: 1 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                    <Button
                                                        onClick={() => setClassTeacherDetails([...classTeacherDetails, { standard: '', section: '' }])}
                                                        variant="outlined"
                                                        sx={{ mt: 1 }}
                                                    >
                                                        Assign More
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                ))}
                                {classTeacherDetails.length === 1 && (
                                    <Button
                                        onClick={() => setClassTeacherDetails([...classTeacherDetails, { standard: '', section: '' }])}
                                        variant="outlined"
                                        sx={{ mt: 2 }}
                                    >
                                        Assign More
                                    </Button>
                                )}
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" width="100%" marginTop={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loader}
                            >
                                {loader ? <CircularProgress size={24} color="inherit" /> : 'Enroll'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default FacultyEnrollment;
