

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { AuthContext } from './context/AuthContext';
import toast from "react-hot-toast";

const Logout = () => {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext); // Access the logout function and isLoggedIn state from AuthContext

  const handleLogout = () => {
  

    // Call logout function from context to handle logout
    logout();

    toast.success('Logged out successfully');

    // Redirect to login page
    navigate('/loginPage');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Typography variant="h4" component="h2" align="center" gutterBottom>
          Logout
        </Typography>
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Typography variant="body1">
            Are you sure you want to logout?
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <LogoutButton variant="contained" onClick={handleLogout}>
            Logout
          </LogoutButton>
        </Box>
      </Box>
    </Container>
  );
};

// Styled button using Material-UI styled function
const LogoutButton = styled(Button)({
  marginTop: '16px',
  backgroundColor: '#f44336', // Red color for logout button
  color: '#fff', // White text color
  '&:hover': {
    backgroundColor: '#d32f2f', // Darker red on hover
  },
});

export default Logout;
