
import React, { useContext } from 'react';
import { Container, Grid, Box } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ErpNavbar from './ErpNavbar';
import { LightPurpleButton } from '../components/buttonStyles';
import Students from "../assets/students.svg";
import { AuthContext } from './context/AuthContext';
import ThreeDCardDemo from '../components/ui/3d-card-comp';
import FeatureRevealScroll from '../components/ui/Features-comp';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextGenerateEffectWord from '../components/ui/TextGenerate-comp';
import './Homepage.css';

const Homepage = () => {
  const { isLoggedIn, logout } = useContext(AuthContext);

  return (
   
      <div >
        
        <StyledContainer>
        <Grid item xs={12} className='bg-[#34495E] shadow-lg rounded-lg flex justify-center items-center gap-2 p-1'>         
            <StyledText>
              At Isnartech, our comprehensive ERP solution offers a 180-days trial tailored to your school’s needs.
            </StyledText>
            <StyledLink to="/registerSchool">
              <button className='register-btn' variant="contained" fullWidth>
                Start 180-Days Trial for Your School
                <span>
                  <ArrowForwardIcon />
                </span>
              </button>
            </StyledLink>          
        </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <StyledPaper elevation={3}>
                <StyledTitle>
                  Welcome to
                  <br />
                  Isnartech Comprehensive
                  <br />
                  School ERP Solution
                </StyledTitle>
                <StyledText>
                  <TextGenerateEffectWord />
                </StyledText>
              
              </StyledPaper>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={Students} alt="students" style={{ width: '100%' }} />
            </Grid>
            <div className="bg-[#34495E] shadow-lg rounded-lg p-6 flex justify-center items-center mt-[2rem]">
              <Grid>
                <FeatureRevealScroll />
              </Grid>
            </div>
            <Grid className=' mt-[6rem]'>
              <ThreeDCardDemo />
            </Grid>
          </Grid>
        </StyledContainer>

        {/* Footer Section */}
        <StyledFooter>
          <FooterContent>
            <div className="flex justify-between flex-start items-center">
              <div className="flex flex-col  items-start ml-8">
                <FooterHeading>Contact Us</FooterHeading>
                <FooterText>Email: <a href="mailto:enquiries@isnartech.com">enquiries@isnartech.com</a></FooterText>
                <FooterText>Phone: +91-9891940289, +91-7704836280</FooterText>
                <FooterText>Address: D-302, Tower D, Sector-102, Gurgaon, India</FooterText>
              </div>
              <div className="flex flex-col items-start ml-10">
                <FooterHeading>Legal</FooterHeading>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
                <FooterLink to="/terms-and-conditions">Terms & Conditions</FooterLink>
              </div>
            </div>
          </FooterContent>
        </StyledFooter>
      </div>
    
  );
};

export default Homepage;

const StyledContainer = styled(Container)`
  margin-top: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 64px);
`;

const StyledPaper = styled.div`
  padding: 24px;
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px;
`;

const StyledTitle = styled.h1`
  font-size: 3rem;
  color: #D0D3D4 ;
  font-weight: bold;
  padding-top: 0;
`;

const StyledText = styled.p`
  margin-top: 30px;
  margin-bottom: 30px;
  color: #E5E7E9 ;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const StyledFooter = styled.footer`
  background-color: #141e30;
  color: white;
  padding: 20px;
  margin-top: 5rem;
`;

const FooterContent = styled.div`
  max-width: 1200px;


`;

const FooterHeading = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const FooterText = styled.p`
  font-size: 1rem;
  margin-bottom: 8px;
`;

const FooterLink = styled(Link)`
  color: white;
  text-decoration: none;
  transition: opacity 0.3s;
  font-size: 1rem;
  margin-bottom: 8px;

  &:hover {
    opacity: 0.8;
  }
`;

