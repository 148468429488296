

import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  TextField,
  CircularProgress,
  Backdrop,
  Button,
  Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { AuthContext } from './context/AuthContext';

// Import background image (replace with your actual image import)
import bgpic from "../assets/designlogin.jpg";

const defaultTheme = createTheme();

const OTPVerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { verifyOTP, sendOTP } = useContext(AuthContext);

  const [otp, setOTP] = useState('');
  const [loader, setLoader] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(180); // 3 minutes in seconds

  // Extract email from location state
  const email = location.state?.email || '';

  useEffect(() => {
    let timer = null;
    if (countdown > 0 && resendDisabled) {
      timer = setTimeout(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      setResendDisabled(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [countdown, resendDisabled]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!otp) {
      toast.error('Please enter OTP');
      return;
    }

    setLoader(true);
    const success = await verifyOTP(email, otp);
    setLoader(false);
  };

  const handleResendOTP = async () => {
    setLoader(true);
    const success = await sendOTP(email);
    setLoader(false);

    if (success) {
      setResendDisabled(true);
      setCountdown(180); // Reset countdown timer to 3 minutes
    }
  };

  const handleInputChange = (event) => {
    setOTP(event.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${bgpic})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: 500,
              p: 4,
              textAlign: 'center',              
              boxShadow: 3,
              borderRadius: 1,
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, color: '#2c2143' }}>
              OTP Verification
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Please enter the OTP sent to <strong>{email}</strong>.
            </Typography>
            <form noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="otp"
                label="Enter OTP"
                name="otp"
                type="text"
                autoFocus
                value={otp}
                onChange={handleInputChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loader}
                sx={{ mt: 3 }}
              >
                Verify OTP
              </Button>
            </form>
            <Typography variant="body2" sx={{ mt: 2 }}>
              {resendDisabled ? (
                `Resend OTP in ${Math.floor(countdown / 60)}:${
                  countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60
                } minutes`
              ) : (
                <Button variant="text" onClick={handleResendOTP} disabled={loader}>
                  Resend OTP
                </Button>
              )}
            </Typography>
          </Box>
        </Grid>
        {/* <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="primary" />
          Please Wait
        </Backdrop> */}
      </Grid>
    </ThemeProvider>
  );
};

export default OTPVerificationPage;
