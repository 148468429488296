

import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField, Button, Box, Typography } from '@mui/material';
import toast from "react-hot-toast";

const AdminProfile = ({ userId }) => {
    const [admin, setAdmin] = useState(null);
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        fetchAdminDetails();
    }, []);

    const fetchAdminDetails = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getAdminById?userId=${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch admin');
            }
            const data = await response.json();
            if (data && data.admin) {
                setAdmin(data.admin[0]);
            }
        } catch (error) {
            toast.error('Error fetching admin:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (prop) => (event) => {
        setAdmin({ ...admin, [prop]: event.target.value });
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateAdmin/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName: admin.fullName,
                    aadhaarNumber: admin.aadhaarNumber,
                    address: admin.address,
                    email: admin.email,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to update admin');
            }
            toast.success('Profile updated successfully');
            setEditable(false);
        } catch (error) {
            console.error('Error updating admin:', error);
            toast.error('Failed to update profile');
        }
    };

    if (loading) {
        return <TextField fullWidth label="Loading..." disabled />;
    }

    return (
        <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
            <Box sx={{ backgroundColor: '#3f51b5', borderRadius: '8px', marginBottom: '20px' }}>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    style={{
                        fontWeight: 500,
                        color: '#fff',
                        padding: '8px 0',
                        margin: '0 auto', // Center align within the container
                        maxWidth: '100%', // Ensure it spans the full width of the container
                    }}
                >
                    Update Your Profile
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Full Name"
                        value={admin.fullName || ''}
                        onChange={handleChange('fullName')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Aadhaar Number"
                        value={admin.aadhaarNumber || ''}
                        onChange={handleChange('aadhaarNumber')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Address"
                        value={admin.address || ''}
                        onChange={handleChange('address')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={admin.email || ''}
                        onChange={handleChange('email')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '20px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={editable ? handleSave : () => setEditable(true)}
                    >
                        {editable ? 'Save' : 'Edit'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default AdminProfile;
