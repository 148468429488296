// import React, { useState, useEffect } from 'react';
// import { Container, Grid, Box, Typography, Card, CardContent } from '@mui/material';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom'; // Import Link from react-router-dom
// import Navbar from './Navbar';
// import { LightPurpleButton } from '../components/buttonStyles';

// import { AuthContext } from './context/AuthContext';
// import { useLocation } from 'react-router-dom';
// import { motion } from 'framer-motion'; // Import motion from framer-motion
// import AshokFirstImg from '../assets/ask1.jpg';
// import AshokSecImg from '../assets/ask2.jpg';
// import './FirstPage.css';

// const NoticeCard = styled(motion.div)`
//   background-color: #ffffff;
//   padding: 20px;
//   margin: 10px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//   border: 2px solid #e0e0e0; /* Border style */
// `;

// const BackgroundSlider = styled.div`
//   height: 300px; /* Adjust height as needed */
//   background: url(${AshokFirstImg}); /* Use imported image */
//   background-size: cover;
//   background-position: center;
//   animation: slide 10s infinite alternate; /* Adjust animation duration and type */
//   @keyframes slide {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
// `;

// const DecoratedTypography = styled(Typography)`
//   && {
//     margin-top: 20px;
//     margin-bottom: 20px;
//     padding: 10px;
//     background-color: #f5f5f5; /* Light gray background */
//     border-radius: 5px;
//     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
//   }
// `;

// const FirstPage = () => {
//   const location = useLocation();  
//   const email = location.state?.email;
//   const schoolName = localStorage.getItem('schoolName');
//   const schoolId = localStorage.getItem('schoolId');

//   const[schoolData, setSchoolData] = useState(null);
//   // Dummy data for notices (replace with actual dynamic data)
//   const notices = [
//     { id: 1, title: 'Important Notice 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//     { id: 2, title: 'Event Coming Soon', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
//   ];

//   useEffect(()=>{
//     fetchSchool();
//   },[]);

//   async function fetchSchool(){
//     const response = await fetch(`http://localhost:4000/api/v1/getSchool?schoolId=${schoolId}`);
//     const data = response.Json();
//     setSchoolData(data.school);

//   }

//   return (
//     <>
//       <Navbar />

//       <Container maxWidth="lg" className='firstContainer'>
//         <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
//           <Grid item xs={12}>
//             <Box className="welcomeBox">
//               <Typography className="SchoolName-text" variant="h3" gutterBottom>
//                 Welcome to <span className='schoolName'>{schoolName}</span>
//               </Typography>
//               <BackgroundSlider className="backgroundSlider" />

//             </Box>
//             <div className='school-desc'>
//               <Typography className="bodyText" >
//                 Our Institution baptized as ASHOKA INTERNATIONAL SCHOOL was established in 2016 as an English medium school. The school was born out of a conceptualized vision to broaden the scope of education accentuated with ethical learning. Our institution located in Bahadurgarh, Haryana is a fledgling of now but is gradually growing in a dignified space with highly dedicated faculty to guide and inspire the future generations to become recognizable icons of tomorrow. The school is run under the watchful eyes of reputed academicians such as Dr. Ashok Kumar Sharma, the Chairman is a retired Professor and has authored nearly 40 books on Commerce and Economics. Smt. Vijay Lakshmi Sharma, the Director of the school is a retired Professor of Physics who has guided many children to make sterling performances in IITJEE and Civil Services preparations
//               </Typography>
//             </div>

//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box mb={3}>
//               <DecoratedTypography variant="h5" gutterBottom>
//                 Important Notices
//               </DecoratedTypography>
//               {notices.map(notice => (
//                 <NoticeCard key={notice.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
//                   <Typography variant="subtitle1" gutterBottom>{notice.title}</Typography>
//                   <Typography variant="body2">{notice.content}</Typography>
//                 </NoticeCard>
//               ))}
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box mb={3}>
//               <DecoratedTypography variant="h5" gutterBottom>
//                 Objectives of {schoolName}
//               </DecoratedTypography>
//               <Typography variant="body1" paragraph>
//                 The Management, Principal and staff at Ashoka International School believe that every child is an individual and capable of achieving his/her full potential. Our objectives will be achieved through the combined efforts of teachers, parents, and students, which are as follows:
//               </Typography>
//               <ul>
//                 <li>
//                 to provide a secure, positive, and intellectually stimulating environment that nurtures individual development
//                 </li>
//                 <li>
//                 to encourage independent thinking and self-directed learning in a child-centred environment
//                 </li>
//                 <li>
//                 to provide learning experiences that develop Sushikshit, Susanskrit, Susaksham child
//                 </li>
//                 <li>
//                 to provide multimodal learning experiences that develop children’s unique gifts and multipl intelligences.
//                 </li>
//                 <li>
//                 to foster language development by providing a variety of authentic learning experiences
//                 </li>
//                 <li>
//                 to foster children’s intellectual development by designing learning experiences that incorporate higher-order thinking operations
//                 </li>
//                 <li>
//                 to provide Computer literacy so that students learn to use technology efficiently.
//                 </li>
//                 <li>
//                 to promote on-going parent, staff and child communication
//                 </li>
//               </ul>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>
//     </>
//   );
// };

// export default FirstPage;

// import React, { useState, useEffect } from 'react';
// import { Container, Grid, Box, Typography, Card, CardContent } from '@mui/material';
// import styled from 'styled-components';
// import { Link } from 'react-router-dom';
// import { motion } from 'framer-motion';
// import Navbar from './Navbar';
// import Footer from './Footer'; // Import Footer component
// import { LightPurpleButton } from '../components/buttonStyles';

// import { AuthContext } from './context/AuthContext';
// import { useLocation } from 'react-router-dom';
// import AshokFirstImg from '../assets/ask1.jpg';
// import AshokSecImg from '../assets/ask2.jpg';
// import './FirstPage.css';

// const NoticeCard = styled(motion.div)`
//   background-color: #ffffff;
//   padding: 20px;
//   margin: 10px;
//   border-radius: 10px;
//   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//   border: 2px solid #e0e0e0;
// `;

// const BackgroundSlider = styled.div`
//   height: 300px;
//   width: 100%; /* Ensure full width */
//   background: url(${AshokFirstImg});
//   background-size: cover; /* Scale the image to fit the container */
//   background-repeat: no-repeat; /* Prevent repeating of background image */
//   background-position: center top; /* Center the image horizontally and vertically */

//   animation: slide 10s infinite alternate;
//   @keyframes slide {
//     0% { opacity: 0; }
//     100% { opacity: 1; }
//   }
// `;

// const DecoratedTypography = styled(Typography)`
//   && {
//     margin-top: 20px;
//     margin-bottom: 20px;
//     padding: 10px;
//     background-color: #f5f5f5;
//     border-radius: 5px;
//     box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
//   }
// `;

// const FirstPage = () => {
//   const location = useLocation();
//   const email = location.state?.email;
//   const schoolId = localStorage.getItem('schoolId');

//   const [schoolData, setSchoolData] = useState([]);

//   useEffect(() => {
//     fetchSchool();
//   }, []);

//   async function fetchSchool() {
//     const response = await fetch(`http://localhost:4000/api/v1/getSchool?schoolId=${schoolId}`);
//     const data = await response.json();
//     setSchoolData(data.school[0]);
//   }

//   const notices = [
//      { id: 1, title: 'Important Notice 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
//      { id: 2, title: 'Event Coming Soon', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
//    ];
//   return (
//     <>
//       <Navbar />

//       <Container maxWidth="lg" className='firstContainer'>
//         <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
//           <Grid item xs={12}>
//             <Box className="welcomeBox">
//               <Typography className="SchoolName-text" variant="h3" gutterBottom>
//                 Welcome to <span className='schoolName'>{schoolData.schoolName}</span>
//               </Typography>
//               <BackgroundSlider className="backgroundSlider" />
//             </Box>
//             <div className='school-desc'>
//               <Typography className="bodyText">
//                 {schoolData?.description}
//               </Typography>
//             </div>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box mb={3}>
//               <DecoratedTypography variant="h5" gutterBottom>
//                 Important Notices
//               </DecoratedTypography>
//               {/* Notices content */}
//               {notices.map(notice => (<NoticeCard key={notice.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
//                 <Typography variant="subtitle1" gutterBottom>{notice.title}</Typography>
//                 <Typography variant="body2">{notice.content}</Typography>
//               </NoticeCard>
//               ))}
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <Box mb={3}>
//               <DecoratedTypography variant="h5" gutterBottom>
//                 Objectives of {schoolData?.schoolName}
//               </DecoratedTypography>
//               {/* Objectives content */}
//               <Typography variant="body1" paragraph>
//                  The Management, Principal and staff at Ashoka International School believe that every child is an individual and capable of achieving his/her full potential. Our objectives will be achieved through the combined efforts of teachers, parents, and students, which are as follows:
//                </Typography>
//                <ul>
//                 <li>
//                 to provide a secure, positive, and intellectually stimulating environment that nurtures individual development
//                 </li>
//                 <li>
//                  to encourage independent thinking and self-directed learning in a child-centred environment
//                  </li>
//                  <li>
//                  to provide learning experiences that develop Sushikshit, Susanskrit, Susaksham child
//                  </li>
//                  <li>
//                  to provide multimodal learning experiences that develop children’s unique gifts and multipl intelligences.
//                  </li>
//                  <li>
//                  to foster language development by providing a variety of authentic learning experiences
//                  </li>
//                  <li>
//                  to foster children’s intellectual development by designing learning experiences that incorporate higher-order thinking operations
//                  </li>
//                  <li>
//                  to provide Computer literacy so that students learn to use technology efficiently.
//                  </li>
//                  <li>
//                  to promote on-going parent, staff and child communication
//                 </li>
//                </ul>
//             </Box>
//           </Grid>
//         </Grid>
//       </Container>

//       {/* Footer */}
//       {schoolData && <Footer schoolData={schoolData} />}
//     </>
//   );
// };

// export default FirstPage;

import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, Typography, Card, CardContent } from '@mui/material';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Footer from './Footer'; // Import Footer component
import { LightPurpleButton } from '../components/buttonStyles';

import { AuthContext } from './context/AuthContext';
import { useLocation } from 'react-router-dom';
import AshokFirstImg from '../assets/ask1.jpg';
import AshokSecImg from '../assets/ask2.jpg';
import './FirstPage.css';

const NoticeCard = styled(motion.div)`
  background-color: #ffffff;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid #e0e0e0;
`;

const BackgroundSlider = styled.div`
  height: 300px;
  width: 100%; /* Ensure full width */
  background: url(${AshokFirstImg});
  background-size: cover; /* Scale the image to fit the container */
  background-repeat: no-repeat; /* Prevent repeating of background image */
  background-position: center top; /* Center the image horizontally and vertically */
  
  animation: slide 10s infinite alternate;
  @keyframes slide {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const DecoratedTypography = styled(Typography)`
  && {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
`;

const AnnouncementContainer = styled.div`
  max-height: 300px; /* Set maximum height for scrollability */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const FirstPage = () => {
  const location = useLocation();
  const email = location.state?.email;
  const schoolId = localStorage.getItem('schoolId');

  const [schoolData, setSchoolData] = useState([]);
  const [announcements, setAnnouncements] = useState([]);

  useEffect(() => {
    fetchSchool();
    // Fetch announcements (static for now)
    const staticAnnouncements = [
      { id: 1, title: 'Important Announcement 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
      { id: 2, title: 'Upcoming Event', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
      { id: 3, title: 'Holiday Schedule', content: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.' },
      { id: 4, title: 'Exam Timetable', content: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.' },
      { id: 5, title: 'Parent-Teacher Meeting', content: 'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.' },
    ];
    setAnnouncements(staticAnnouncements);
  }, []);

  const notices = [
    { id: 1, title: 'Important Notice 1', content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
    { id: 2, title: 'Event Coming Soon', content: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' },
  ];

  async function fetchSchool() {
    const response = await fetch(`https://api.isnartecherp.online/api/v1/getSchool?schoolId=${schoolId}`);
    const data = await response.json();
    setSchoolData(data.school[0]);
  }

  return (
    <>
      <Navbar />

      <Container maxWidth="lg" className='firstContainer'>
        <Grid container spacing={3} justifyContent="center" alignItems="flex-start">
          <Grid item xs={12}>
            <Box className="welcomeBox">
              <Typography className="SchoolName-text" variant="h3" gutterBottom>
                Welcome to <span className='schoolName'>{schoolData.schoolName}</span>
              </Typography>
              <BackgroundSlider className="backgroundSlider" />
            </Box>
            <div className='school-desc'>
              <Typography className="bodyText">
                {schoolData?.description}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
          <Box mb={3}>
              <DecoratedTypography variant="h5" gutterBottom>
               Notice
              </DecoratedTypography>
              {/* Notices content */}
              {notices.length > 0 ? (
                <AnnouncementContainer>
                  {notices.map(notice => (
                    <NoticeCard key={notice.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Typography variant="subtitle1" gutterBottom>{notice.title}</Typography>
                      <Typography variant="body2">{notice.content}</Typography>
                    </NoticeCard>
                  ))}
                </AnnouncementContainer>
              ) : (
                <Typography variant="body2">No announcements available.</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <DecoratedTypography variant="h5" gutterBottom>
                Announcement
              </DecoratedTypography>
              {/* Notices content */}
              {announcements.length > 0 ? (
                <AnnouncementContainer>
                  {announcements.map(notice => (
                    <NoticeCard key={notice.id} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                      <Typography variant="subtitle1" gutterBottom>{notice.title}</Typography>
                      <Typography variant="body2">{notice.content}</Typography>
                    </NoticeCard>
                  ))}
                </AnnouncementContainer>
              ) : (
                <Typography variant="body2">No announcements available.</Typography>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Placeholder */}
          </Grid>
        </Grid>
      </Container>

      {/* Footer */}
      {schoolData && <Footer schoolData={schoolData} />}
    </>
  );
};

export default FirstPage;
