


import React, { useState, useEffect } from "react";
import { Typography, Grid, Paper } from "@mui/material";
import { School, People, Work, SupervisorAccount } from "@mui/icons-material";

const HomePage = ({schoolId}) => {
    const [studentCount, setStudentCount] = useState(0);
    const [facultyCount, setFacultyCount] = useState(0);
    const [staffCount, setStaffCount] = useState(0);
    

    useEffect(() => {
        // Fetch student count
        fetch(`https://api.isnartecherp.online/api/v1/getAllStudents?schoolId=${schoolId}`)
            .then(response => response.json())
            .then(data => setStudentCount(data.students.length))
            .catch(error => console.error('Error fetching students:', error));

        // Fetch faculty count
        fetch(`https://api.isnartecherp.online/api/v1/getAllFaculty?schoolId=${schoolId}`)
            .then(response => response.json())
            .then(data => setFacultyCount(data.faculty.length))
            .catch(error => console.error('Error fetching faculty:', error));

        // Fetch staff count
        fetch(`https://api.isnartecherp.online/api/v1/getAllStaff?schoolId=${schoolId}`)
            .then(response => response.json())
            .then(data => setStaffCount(data.staff.length))
            .catch(error => console.error('Error fetching staff:', error));

     
    }, [schoolId]);

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} style={{ backgroundColor: '#f44336', color: '#fff', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <School fontSize="large" />
                        <Typography variant="h5" style={{ marginTop: 10 }}>Students Enrolled</Typography>
                        <Typography variant="h3">{studentCount}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} style={{ backgroundColor: '#3f51b5', color: '#fff', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <People fontSize="large" />
                        <Typography variant="h5" style={{ marginTop: 10 }}>Faculty Enrolled</Typography>
                        <Typography variant="h3">{facultyCount}</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={3} style={{ backgroundColor: '#4caf50', color: '#fff', padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Work fontSize="large" />
                        <Typography variant="h5" style={{ marginTop: 10 }}>Staff Enrolled</Typography>
                        <Typography variant="h3">{staffCount}</Typography>
                    </Paper>
                </Grid>
              
            </Grid>
        </div>
    );
};

export default HomePage;
