

import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField, Button, Box, Typography } from '@mui/material';
import toast from 'react-hot-toast';

const FacultyProfile = ({ userId }) => {
    const [faculty, setFaculty] = useState({});
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchFacultyDetails();
    }, []);

    const fetchFacultyDetails = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getFacultiesByStandardId?userId=${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch faculty');
            }
            const data = await response.json();
            if (data && data.faculty && data.faculty.length > 0) {
                setFaculty(data.faculty[0]); // Assuming you want to show the first faculty member
            }
        } catch (error) {
            console.error('Error fetching faculty:', error);
            toast.error('Failed to fetch faculty details');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (prop) => (event) => {
        setFaculty({ ...faculty, [prop]: event.target.value });
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateFaculty/${faculty._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName: faculty.fullName,
                    aadhaarNumber: faculty.aadhaarNumber,
                    address: faculty.address,
                    email: faculty.email,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to update faculty');
            }
            toast.success('Profile updated successfully');
            setEditable(false);
        } catch (error) {
            console.error('Error updating faculty:', error);
            toast.error('Failed to update profile');
        }
    };

    if (loading) {
        return <TextField fullWidth label="Loading..." disabled />;
    }

    return (
        <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
            <Box sx={{ backgroundColor: '#3f51b5', borderRadius: '8px', marginBottom: '20px' }}>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    style={{
                        fontWeight: 500,
                        color: '#fff',
                        padding: '8px 0',
                        margin: '0 auto', // Center align within the container
                        maxWidth: '100%', // Ensure it spans the full width of the container
                    }}
                >
                    Update Your Profile
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Full Name"
                        value={faculty.fullName || ''}
                        onChange={handleChange('fullName')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '10px' }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Aadhaar Number"
                        value={faculty.aadhaarNumber || ''}
                        onChange={handleChange('aadhaarNumber')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '10px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Address"
                        value={faculty.address || ''}
                        onChange={handleChange('address')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '10px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={faculty.email || ''}
                        onChange={handleChange('email')}
                        disabled={!editable}
                        InputProps={{
                            readOnly: !editable,
                        }}
                        style={{ marginBottom: '10px' }}
                    />
                </Grid>
                <Grid item xs={12}>
                   
                     <Box marginBottom="20px">
                        <TextField
                            fullWidth
                            multiline
                            label="Assigned Standards and Section"
                            value={faculty.assignments ? faculty.assignments.map(detail => `${detail.standard} - ${detail.section}`).join('\n') : ''}
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                    <Box marginBottom="20px">
                        <TextField
                            fullWidth
                            label="Class Teacher"
                            value={faculty.classTeacher ? 'Yes' : 'No'}
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                    <Box marginBottom="20px">
                        <TextField
                            fullWidth
                            multiline
                            label="Assigned Class Teacher Details"
                            value={faculty.classTeacherDetails ? faculty.classTeacherDetails.map(detail => `${detail.standard} - ${detail.section}`).join('\n') : ''}
                            disabled
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={editable ? handleSave : () => setEditable(true)}
                    >
                        {editable ? 'Save' : 'Edit'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default FacultyProfile;

