


// StudentHomePage.js

import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const assignmentData = [
  { name: 'Completed', value: 15 },
  { name: 'Incomplete', value: 5 },
  { name: 'Submitted', value: 10 } // New category for submitted assignments
];

const attendanceData = [
  { month: 'January', attendance: 20 },
  { month: 'February', attendance: 15 },
  { month: 'March', attendance: 18 },
  { month: 'April', attendance: 16 },
  { month: 'May', attendance: 10 },
  { month: 'June', attendance: 0 }, // Placeholder for June (no data provided)
  { month: 'July', attendance: 12 },
  { month: 'August', attendance: 17 },
  { month: 'September', attendance: 21 },
  { month: 'October', attendance: 18 },
  { month: 'November', attendance: 16 },
  { month: 'December', attendance: 6 }
];

// Define colors for pie chart slices
const COLORS = ['#0088FE', '#FF8042', '#008b8b', '#006400', '#8fbc8f', '#00C49F', '#FFBB28', '#00bfff', '#778899', '#f0e68c', '#add8e6', '#ffa07a'];

const StudentHomePage = () => {
  // Calculate total sessions and total months
  const totalSessions = 1; // Assuming 1 session per month
  const totalMonths = 12; // Total months in a year

  // Calculate total attendance and percentages
  const totalAttendance = attendanceData.reduce((sum, entry) => sum + entry.attendance, 0);
  const totalPresentPercentage = ((totalAttendance / (totalMonths * 20)) * 100).toFixed(2); // Assuming 20 days per month
  const totalAbsentPercentage = ((1 - (totalAttendance / (totalMonths * 20))) * 100).toFixed(2); // Assuming 20 days per month

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
      {/* Assignments Overview */}
      <div>
        <h2>Assignments Overview</h2>
        <PieChart width={400} height={400}>
          <Pie
            data={assignmentData}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#8884d8"
            label
          >
            {assignmentData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
        <div style={{ marginTop: '20px' }}>
          <h3>Assignment Details</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>Total Assignments: {assignmentData.reduce((sum, current) => sum + current.value, 0)}</li>
            {assignmentData.map((entry, index) => (
              <li key={index}>
                {entry.name}: {entry.value}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Monthly Attendance Pie Chart */}
      <div>
        <h2>Monthly Attendance</h2>
        <PieChart width={400} height={400}>
          <Pie
            data={attendanceData}
            dataKey="attendance"
            cx="50%"
            cy="50%"
            outerRadius={80}
            label={(entry) => `${entry.month}: ${entry.attendance}`} // Custom label showing month and attendance
          >
            {attendanceData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>

        {/* Attendance Details */}
        <div style={{ marginTop: '20px' }}>
          <h3>Attendance Details</h3>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li>Total Sessions: {totalSessions}</li>
            <li>Total Months: {totalMonths}</li>
            <li>Total Attendance: {totalAttendance}</li>
            <li>Total Present Percentage: {totalPresentPercentage}%</li>
            <li>Total Absent Percentage: {totalAbsentPercentage}%</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StudentHomePage;

