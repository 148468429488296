import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar, Drawer } from '../../components/styles';
import StudentSideBar from './StudentSidebar';
import StudentAttendance from './StudentAttendance';
import StudentCourses from './StudentCourses';
import StudentProfile from './stuProfile';
import StudentHomePage from './stuHomePage';
import Logout from '../Logout';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import StudentAssignment from './StudentAssignment';
import LeaveRequest from './LeaveRequest';

const StudentDashboard = () => {
    const location = useLocation();
    const schoolId = location.state?.schoolId;
    const rollNumber = location.state?.rollNumber;
    const userId = location.state?._id;
    const standard = location.state?.standard;
    const section = location.state?.section;
    const fullName = location.state?.fullName;

    const [open, setOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState('Home');

    console.log(fullName);
    console.log(schoolId);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const renderContent = () => {
        switch (selectedPage) {
            case 'StudentHomePage':
                return <StudentHomePage schoolId={schoolId} />;
            case 'StudentAttendance':
                return <StudentAttendance schoolId={schoolId} rollNumber={rollNumber} userId={userId} />;
            case 'StudentCourses':
                return <StudentCourses schoolId={schoolId} />;
            case 'Assignments':
                return <StudentAssignment schoolId={schoolId} standard={standard} section={section} rollNumber={rollNumber} userId={userId} fullName={fullName} />;
            case 'StudentProfile':
                return <StudentProfile schoolId={schoolId} userId={userId} />;
            case 'studentLeaveRequest':
                return <LeaveRequest schoolId={schoolId} userId={userId} standard={standard} section={section} fullName={fullName} rollNumber={rollNumber} />;
            case 'Logout':
                return <Logout />;

            default:
                return <StudentHomePage />;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} position='absolute'>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Student Dashboard
                    </Typography>
                    <IconButton
                        color="inherit"
                        component={Link}
                        to="/firstPage"
                        sx={{
                            marginLeft: 'auto', // This will push the button to the right end
                            textDecoration: 'none', // Ensure no underline
                        }}
                    >
                        <HomeIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={open ? styles.drawerStyled : styles.hideDrawer}>
                <Toolbar sx={styles.toolBarStyled}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <StudentSideBar setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
                </List>
            </Drawer>
            <Box component="main" sx={styles.boxStyled}>
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}

export default StudentDashboard

const styles = {
    boxStyled: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: '20px'
    },
    toolBarStyled: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
    },
    drawerStyled: {
        display: "flex"
    },
    hideDrawer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
}
