import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Grid,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import toast from 'react-hot-toast';

const AllStaff = ({schoolId}) => {
    const [staff, setStaff] = useState([]);
    const [editingStaff, setEditingStaff] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        fetchStaff();
    }, [schoolId]);

    const fetchStaff = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getAllStaff?schoolId=${schoolId}`);
            const data = await response.json();
            setStaff(data.staff);
        } catch (error) {
            console.error('Error fetching staff:', error);
        }
    };

    const handleEditClick = (staffMember) => {
        setEditingStaff(staffMember);
        setOpenEditDialog(true);
    };

    const handleEditDialogClose = () => {
        setEditingStaff(null);
        setOpenEditDialog(false);
    };

    const handleDeleteClick = (staffMember) => {
        setEditingStaff(staffMember);
        setOpenDeleteDialog(true);
    };

    const handleDeleteDialogClose = () => {
        setEditingStaff(null);
        setOpenDeleteDialog(false);
    };

    const handleUpdateStaff = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateStaffById/${editingStaff._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingStaff),
            });
            if (response.ok) {
                console.log('Staff member updated:', editingStaff);
                setOpenEditDialog(false);
                setEditingStaff(null);
                fetchStaff(); // Refetch staff after update
                toast.success('Staff information updated successfully');
            } else {
                console.error('Failed to update staff member:', response.statusText);
                toast.error('Failed to update staff member');
            }
        } catch (error) {
            console.error('Error updating staff member:', error);
            toast.error('Failed to update staff member');
        }
    };

    const handleDeleteStaff = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/deleteStaffById/${editingStaff._id}`, {
                method: 'PUT',
            });
            if (response.ok) {
                console.log('Staff member deleted:', editingStaff);
                setOpenDeleteDialog(false);
                setEditingStaff(null);
                fetchStaff(); // Refetch staff after delete
                toast.success('Staff member deleted successfully');
            } else {
                console.error('Failed to delete staff member:', response.statusText);
                toast.error('Failed to delete staff member');
            }
        } catch (error) {
            console.error('Error deleting staff member:', error);
            toast.error('Failed to delete staff member');
        }
    };

    const handleCloseDialogs = () => {
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
    };

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Staff Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Address</TableCell>
                                    <TableCell>Position</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {staff.map((staffMember) => (
                                    <TableRow key={staffMember._id}>
                                        <TableCell>{staffMember.fullName}</TableCell>
                                        <TableCell>{staffMember.email}</TableCell>
                                        <TableCell>{staffMember.address}</TableCell>
                                        <TableCell>{staffMember.position}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditClick(staffMember)}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteClick(staffMember)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
                <DialogTitle>Edit Staff Member</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fullName"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={editingStaff?.fullName}
                        onChange={(e) =>
                            setEditingStaff({
                                ...editingStaff,
                                fullName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editingStaff?.email}
                        onChange={(e) =>
                            setEditingStaff({
                                ...editingStaff,
                                email: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="position"
                        label="Position"
                        type="text"
                        fullWidth
                        value={editingStaff?.position}
                        onChange={(e) =>
                            setEditingStaff({
                                ...editingStaff,
                                position: e.target.value,
                            })
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateStaff} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Staff Member</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this staff member?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteStaff} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AllStaff;
