

import React, { useState } from 'react';
import { CircularProgress, Container, TextField, Button, Typography, MenuItem, Grid, Box } from '@mui/material';
// import Popup from '../components/Popup';
import Popup from '../../components/Popup';
import toast from "react-hot-toast";

const StudentEnrollment = ({ schoolId, selectedPage }) => {
    
    const [userType, setUserType] = useState('student');
    const [fullName, setFullName] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [standard, setStandard] = useState('');
    const [stream, setStream] = useState('');
    const [optionalSubject, setOptionalSubject] = useState('');
    const [section, setSection] = useState('');
    const [rollNumber, setRollNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [studentType, setStudentType] = useState('');
    const [previousSchoolDetail, setPreviousSchoolDetail] = useState('');
    const [loader, setLoader] = useState(false);
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const submitHandler = async (event) => {
        event.preventDefault();
        setLoader(true);

        const studentData = {
            userType,
            fullName,
            aadhaarNumber,
            standard,
            stream,
            optionalSubject,
            section,
            rollNumber,
            address,
            email,
            password,
            confirmPassword,
            studentType,
            previousSchoolDetail,
            schoolId,
        };

        try {
            const response = await fetch('https://api.isnartecherp.online/api/v1/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(studentData),
            });

            if (response.ok) {
                // Reset form data after successful submission             
                setFullName('');
                setAadhaarNumber('');
                setStandard('');
                setStream('');
                setOptionalSubject('');
                setSection('');
                setRollNumber('');
                setAddress('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setStudentType('');
                setPreviousSchoolDetail('');

                // Show success toast
                toast.success('Form submitted successfully');
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            // Show error toast
            toast.error('Failed to submit form');
            console.error('Error submitting form:', error);
        } finally {
            setLoader(false);
        }
    };

    const standards = [
        { value: 'Nursery', label: 'Nursery' },
        { value: 'LKG', label: 'L.K.G' },
        { value: 'UKG', label: 'U.K.G' },
        { value: 'I', label: 'I' },
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'V', label: 'V' },
        { value: 'VI', label: 'VI' },
        { value: 'VII', label: 'VII' },
        { value: 'VIII', label: 'VIII' },
        { value: 'IX', label: 'IX' },
        { value: 'X', label: 'X' },
        { value: 'XI', label: 'XI' },
        { value: 'XII', label: 'XII' },
    ];
    const sections = [
        { value: 'A', label: 'A' },
        { value: 'B', label: 'B' },
        { value: 'C', label: 'C' },
        { value: 'D', label: 'D' },
    ];
    const studentTypes = [
        { value: 'Existing', label: 'Existing Student' },
        { value: 'New', label: 'New Student' },
    ];
    const streams = [
        { value: 'Mathematics', label: 'Mathematics' },
        { value: 'Biology', label: 'Biology' },
        { value: 'Commerce', label: 'Commerce' },
        { value: 'Humanity', label: 'Humanity' },
    ];

    const optionalSubjects = [
        { value: 'Physical Education', label: 'Physical Education' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Music', label: 'Music' },
    ];

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ width: '100%', backgroundColor: '#3f51b5', borderRadius: '8px' }}>
                    <Typography
                        variant="h5"
                        align="center"
                        gutterBottom
                        style={{
                            fontWeight: 500,
                            color: '#fff',
                            padding: '8px 0'
                        }}
                    >
                        Enroll Student
                    </Typography>
                </Box>
                <form onSubmit={submitHandler} style={{ width: '100%', marginTop: 16 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="User Type"
                                fullWidth
                                value={userType}
                                onChange={(event) => setUserType(event.target.value)}
                                disabled // Disable editing
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Full Name"
                                fullWidth
                                value={fullName}
                                onChange={(event) => setFullName(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Aadhaar Number"
                                fullWidth
                                value={aadhaarNumber}
                                onChange={(event) => setAadhaarNumber(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Standard"
                                fullWidth
                                value={standard}
                                onChange={(event) => setStandard(event.target.value)}
                                required
                            >
                                {standards.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {['XI', 'XII'].includes(standard) && (
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label="Stream"
                                        fullWidth
                                        value={stream}
                                        onChange={(event) => setStream(event.target.value)}
                                        required
                                    >
                                        {streams.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        label="Optional Subject"
                                        fullWidth
                                        value={optionalSubject}
                                        onChange={(event) => setOptionalSubject(event.target.value)}
                                        required
                                    >
                                        {optionalSubjects.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Section"
                                fullWidth
                                value={section}
                                onChange={(event) => setSection(event.target.value)}
                                required
                            >
                                {sections.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Roll Number"
                                fullWidth
                                value={rollNumber}
                                onChange={(event) => setRollNumber(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                fullWidth
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                fullWidth
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Confirm Password"
                                fullWidth
                                type="password"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                select
                                label="Student Type"
                                fullWidth
                                value={studentType}
                                onChange={(event) => setStudentType(event.target.value)}
                                required
                            >
                                {studentTypes.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {studentType === 'New' && (
                            <Grid item xs={12}>
                                <TextField
                                    label="Previous School Detail"
                                    fullWidth
                                    value={previousSchoolDetail}
                                    onChange={(event) => setPreviousSchoolDetail(event.target.value)}
                                    required
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" width="100%" marginTop={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loader}
                            >
                                {loader ? <CircularProgress size={24} color="inherit" /> : 'Enroll'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </Box>
            {showPopup &&
                <Popup message={message} setShowPopup={setShowPopup} showPopup={showPopup} />
            }
        </Container>
    );
};

export default StudentEnrollment;
