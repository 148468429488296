


import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, TextField, Button, Box, Paper, CircularProgress, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer } from 'recharts';


const COLORS = ['#0088FE', '#FF8042']; // Adjust colors for On Time and Delayed statuses

const StudentAssignment = ({ classTeacherDetails, schoolId }) => {
    const [assignments, setAssignments] = useState([]);
    const [standard, setStandard] = useState('');
    const [section, setSection] = useState('');
    const [assignmentNumber, setAssignmentNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showDetails, setShowDetails] = useState(false); // State to toggle details view
    const [showChart, setShowChart] = useState(false); // State to toggle chart view

    // State for submission status data
    const [submissionStatusData, setSubmissionStatusData] = useState([
        { name: 'On Time', value: 0 },
        { name: 'Delayed', value: 0 },
    ]);

    useEffect(() => {
        if (assignments.length > 0) {
            // Calculate submission status when assignments change
            calculateSubmissionStatus(assignments);
        }
    }, [assignments]);

    const calculateSubmissionStatus = (data) => {
        let onTimeCount = 0;
        let delayedCount = 0;

        data.forEach((assignment) => {
            if (assignment.status === 'In time') {
                onTimeCount++;
            } else {
                delayedCount++;
            }
        });

        setSubmissionStatusData([
            { name: 'On Time', value: onTimeCount },
            { name: 'Delayed', value: delayedCount },
        ]);
    };

    const fetchAssignments = async () => {
        setIsLoading(true);
        try {
            // Adjusted fetch URL to include schoolId, standard, section, and assignmentNumber
            const response = await fetch(`https://api.isnartecherp.online/api/v1/studentSubmittedAssignment?schoolId=${schoolId}&standard=${standard}&section=${section}&assignmentNumber=${assignmentNumber}`);
            const data = await response.json();
            setAssignments(data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching assignments:', error);
            setIsLoading(false);
        }
    };

    const downloadFile = async (fileName) => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/downloadSubmittedAssignmentFile?filePath=${encodeURIComponent(fileName)}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = fileName; // Use the original file name for download
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Error downloading file');
        }
    };

    const handleStandardChange = (event) => {
        setStandard(event.target.value);
        setAssignments([]); // Reset assignments when standard changes
    };

    const handleSectionChange = (event) => {
        setSection(event.target.value);
        setAssignments([]); // Reset assignments when section changes
    };

    const handleAssignmentNumberChange = (event) => {
        setAssignmentNumber(event.target.value);
        setAssignments([]); // Reset assignments when assignment number changes
    };

    const toggleDetails = () => {
        setShowDetails(!showDetails);
        setShowChart(false); // Hide chart when toggling details
    };

    const toggleChart = () => {
        setShowChart(!showChart);
        setShowDetails(false); // Hide details when toggling chart
    };

    return (
        <Container maxWidth="md" style={{ padding: '20px' }}>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="standard-label">Standard</InputLabel>
                                <Select
                                    labelId="standard-label"
                                    value={standard}
                                    onChange={handleStandardChange}
                                    fullWidth
                                >
                                    {classTeacherDetails.map((details, index) => (
                                        <MenuItem key={index} value={details.standard}>{details.standard}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="section-label">Section</InputLabel>
                                <Select
                                    labelId="section-label"
                                    value={section}
                                    onChange={handleSectionChange}
                                    fullWidth
                                >
                                    {classTeacherDetails.map((details, index) => (
                                        <MenuItem key={index} value={details.section}>{details.section}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Assignment Number"
                                value={assignmentNumber}
                                onChange={handleAssignmentNumberChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign='center' marginTop={3} >
                        <Button variant="contained" onClick={fetchAssignments} color="primary">
                            Fetch Assignments
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {!isLoading && assignments.length > 0 && (
                        <>
                            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Typography variant="h6">Total Submitted Assignments: {assignments.length}</Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <Button variant="contained" onClick={toggleDetails} color="primary" style={{ marginRight: '10px' }}>
                                        {showDetails ? 'Hide Details' : 'See Details'}
                                    </Button>
                                    <Button variant="contained" onClick={toggleChart} color="primary">
                                        {showChart ? 'Hide Chart' : 'Show Chart'}
                                    </Button>
                                </Grid>
                                {showDetails && assignments.map((assignment) => (
                                    <Grid item xs={12} key={assignment._id}>
                                        <Paper style={{ padding: '10px', marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div>
                                                <Typography variant="body1">{assignment.name}</Typography>
                                                <Typography variant="body2">
                                                    Standard: {assignment.standard} | Section: {assignment.section} | Roll Number: {assignment.rollNumber}
                                                </Typography>
                                                <Typography variant="body2">Status: {assignment.status}</Typography>
                                            </div>
                                            <div>
                                                {assignment.assignmentsubmissionfiles.map((fileUrl, index) => (
                                                    <Button
                                                        key={index}
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => downloadFile(fileUrl, `Assignment_File_${index + 1}`)}
                                                        style={{ marginRight: '10px', marginTop: '5px' }}
                                                    >
                                                        Download File {index + 1}
                                                    </Button>
                                                ))}
                                            </div>
                                        </Paper>
                                    </Grid>
                                ))}
                                {showChart && (
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography variant="h6">Submission Status</Typography>
                                        <ResponsiveContainer width="100%" height={400}>
                                            <PieChart>
                                                <Pie
                                                    data={submissionStatusData}
                                                    cx="50%"
                                                    cy="50%"
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    fill="#8884d8"
                                                    paddingAngle={5}
                                                    dataKey="value"
                                                    label={({ percent }) => `${(percent * 100).toFixed(0)}%`}
                                                >
                                                    {submissionStatusData.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                                <Legend />
                                            </PieChart>
                                        </ResponsiveContainer>
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    )}
                    {isLoading && (
                        <Box display="flex" justifyContent="center" mt={3}>
                            <CircularProgress />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default StudentAssignment;
