import React from "react";
import StickyScroll from "./FeatureScroll";
import MovingBorderBox from "./MovingBorder-comp";

const FeatureRevealScroll = () => {
  return (
    <div className=" flex flex-col items-center justify-center    text-white ">
      {/* Gradient Background */}
      <div>
      <h2 className=" text-2xl md:text-4xl lg:text-5xl font-bold text-center mb-8 bg-gradient-to-r from-cyan-500 to-emerald-500 text-transparent bg-clip-text">
        Features of our ERP Solution
      </h2>
      
      {/* Content */}
      <div>
        <MovingBorderBox/>
      </div>
      </div>
    </div>
  );
};

export default FeatureRevealScroll;
