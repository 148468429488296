import React from 'react';
import {
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ReportIcon from '@mui/icons-material/Report';
import CreateIcon from '@mui/icons-material/Create';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

const FacultySideBar = ({ setSelectedPage, selectedPage }) => {
    return (
        <>
            <ListItemButton onClick={() => setSelectedPage('FacultyHomePage')}>
                <ListItemIcon>
                    <HomeIcon color={selectedPage === 'FacultyHomePage' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('FacultyAttendance')}>
                <ListItemIcon>
                    <CreateIcon color={selectedPage === 'FacultyAttendance' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Attendance" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('facultyStuRecord')}>
                <ListItemIcon>
                    <AssignmentIndIcon color={selectedPage === 'facultyStuRecord' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Student Record" />
            </ListItemButton>
           
            <ListItemButton onClick={() => setSelectedPage('FacultyAssignment')}>
                <ListItemIcon>
                    <AssignmentIcon color={selectedPage === 'FacultyAssignment' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Assignment" />
            </ListItemButton>
          
            {/* <ListItemButton onClick={() => setSelectedPage('FacultyNotes')}>
                <ListItemIcon>
                    <AutoStoriesIcon color={selectedPage === 'FacultyNotes' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Share Notes"  />
            </ListItemButton>          */}
           

            <ListItemButton onClick={() => setSelectedPage('StudentLeave')}>
                <ListItemIcon>
                    <PersonOutlineIcon color={selectedPage === 'StudentLeave' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Student Leave Request"  />
            </ListItemButton>  
           
          
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                User
            </ListSubheader>
            <ListItemButton onClick={() => setSelectedPage('leaveRequest')}>
                <ListItemIcon>
                    <HolidayVillageIcon color={selectedPage === 'leaveRequest' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Request Leave" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('FacultyProfile')}>
                <ListItemIcon>
                    <AccountCircleOutlinedIcon color={selectedPage === 'FacultyProfile' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Logout')}>
                <ListItemIcon>
                    <ExitToAppIcon color={selectedPage === 'Logout' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </>
    );
}

export default FacultySideBar;