import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar, Drawer } from '../../components/styles';
import FacultySideBar from './FacSidebar';
import FacultyAttendance from './FacAttendance';
import FacultyProfile from './FacProfile';
import FacultyHomePage from './facHomePage';
import FacultyStudentAttendRecord from './FacStuAttendRecord';
import Logout from '../Logout';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import FacultyAssignment from './FacAssignment';
import LeaveRequest from './LeaveRequest';
import StudentLeave from './StudentLeave';


const FacDashboard = () => {
    const location = useLocation();
    const schoolId = location.state?.schoolId;
    const classTeacherDetails = location.state?.classTeacherDetails;
    const userId = location.state?._id;
    const fullName = location.state?.fullName;

    const [open, setOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState('Home');


    console.log(schoolId);
    console.log(classTeacherDetails);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const renderContent = () => {
        switch (selectedPage) {
            case 'FacultyHomePage':
                return <FacultyHomePage schoolId={schoolId} />;
            case 'FacultyAttendance':
                return <FacultyAttendance schoolId={schoolId} classTeacherDetails={classTeacherDetails} />;
            case 'FacultyAssignment':
                return <FacultyAssignment schoolId={schoolId} classTeacherDetails={classTeacherDetails} fullName={fullName} userId={userId} />;
            case 'FacultyProfile':
                return <FacultyProfile schoolId={schoolId} userId={userId} />;
            case 'facultyStuRecord':
                return <FacultyStudentAttendRecord schoolId={schoolId} classTeacherDetails={classTeacherDetails} />;
            case 'StudentLeave':
                return <StudentLeave schoolId={schoolId} />;
            case 'leaveRequest':
                return <LeaveRequest schoolId={schoolId} classTeacherDetails={classTeacherDetails} userId={userId} fullName={fullName} />;
            case 'Logout':
                return <Logout />;

            default:
                return <FacultyHomePage />;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} position='absolute'>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Faculty Dashboard
                    </Typography>
                    <IconButton
                        color="inherit"
                        component={Link}
                        to="/firstPage"
                        sx={{
                            marginLeft: 'auto', // This will push the button to the right end
                            textDecoration: 'none', // Ensure no underline
                        }}
                    >
                        <HomeIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={open ? styles.drawerStyled : styles.hideDrawer}>
                <Toolbar sx={styles.toolBarStyled}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <FacultySideBar setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
                </List>
            </Drawer>
            <Box component="main" sx={styles.boxStyled}>
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}

export default FacDashboard

const styles = {
    boxStyled: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: '20px'
    },
    toolBarStyled: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
    },
    drawerStyled: {
        display: "flex"
    },
    hideDrawer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
}
