import React from 'react';
import {
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ReportIcon from '@mui/icons-material/Report';
import AssignmentIcon from '@mui/icons-material/Assignment';

import DateRangeIcon from '@mui/icons-material/DateRange';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssessmentIcon from '@mui/icons-material/Assessment';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

const StudentSideBar = ({ setSelectedPage, selectedPage }) => {
    return (
        <>
            <ListItemButton onClick={() => setSelectedPage('StudentHomePage')}>
                <ListItemIcon>
                    <HomeIcon color={selectedPage === 'StudentHomePage' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('StudentAttendance')}>
                <ListItemIcon>
                    <AssessmentIcon color={selectedPage === 'StudentAttendance' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Attendance" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('StudentCourses')}>
                <ListItemIcon>
                    <AutoStoriesIcon color={selectedPage === 'StudentCourses' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Courses" />
            </ListItemButton>      
                 
          
           
           
            <ListItemButton onClick={() => setSelectedPage('Assignments')}>
                <ListItemIcon>
                    <AssignmentIcon color={selectedPage === 'Assignments' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Assignments" />
            </ListItemButton>
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                User
            </ListSubheader>
            <ListItemButton onClick={() => setSelectedPage('studentLeaveRequest')}>
                <ListItemIcon>
                    <HolidayVillageIcon color={selectedPage === 'studentLeaveRequest' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Request Leave" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('StudentProfile')}>
                <ListItemIcon>
                    <AccountCircleOutlinedIcon color={selectedPage === 'StudentProfile' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Logout')}>
                <ListItemIcon>
                    <ExitToAppIcon color={selectedPage === 'Logout' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </>
    );
}

export default StudentSideBar;