


"use client";
import React, { useEffect } from 'react';
import { motion, stagger, useAnimate } from "framer-motion";
import { cn } from "../../utils/cn";

export const TextGenerateEffect = ({
  words,
  className,
  filter = true,
  duration = 0.5,
}) => {
  const [scope, animate] = useAnimate();
  
  useEffect(() => {
    if (words) { // Check if words is defined
      let wordsArray = words.split(" ");
      animate(
        "span",
        {
          opacity: 1,
          filter: filter ? "blur(0px)" : "none",
        },
        {
          duration: duration ? duration : 1,
          delay: stagger(0.2),
        }
      );
    }
  }, [scope.current, words]); // Add words to dependencies array

  const renderWords = () => {
    if (!words) return null; // Return null if words is undefined
    let wordsArray = words.split(" ");

    return (
      <motion.div ref={scope}>
        {wordsArray.map((word, idx) => (
          <motion.span
            key={word + idx}
            className="dark:text-white font-medium text-[#CACFD2] opacity-0"
            style={{
              filter: filter ? "blur(10px)" : "none",
            }}
          >
            {word}{" "}
          </motion.span>
        ))}
      </motion.div>
    );
  };

  return (
    <div className={cn( className)}>
      <div className="mt-1">
        <div className=" bg-gradient-to-r from-cyan-500 to-emerald-500 text-transparent bg-clip-text text-[#F4F6F6] text-[18px] leading-snug tracking-wide">
          {renderWords()}
        </div>
      </div>
    </div>
  );
};

