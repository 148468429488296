import React from 'react';
import {
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ReportIcon from '@mui/icons-material/Report';
import CreateIcon from '@mui/icons-material/Create';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DateRangeIcon from '@mui/icons-material/DateRange';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

const StaffSideBar = ({ setSelectedPage, selectedPage }) => {
    return (
        <>
            <ListItemButton onClick={() => setSelectedPage('StaffHomePage')}>
                <ListItemIcon>
                    <HomeIcon color={selectedPage === 'StaffHomePage' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('StaffAttendance')}>
                <ListItemIcon>
                    <CreateIcon color={selectedPage === 'StaffAttendance' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Attendance" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('StaffRecord')}>
                <ListItemIcon>
                    <AssignmentIndIcon color={selectedPage === 'StaffStuRecord' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Staff Record" />
            </ListItemButton>        
           
          
               
           
           
          
            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                User
            </ListSubheader>

            <ListItemButton onClick={() => setSelectedPage('StaffLeaveRequests')}>
                <ListItemIcon>
                    <HolidayVillageIcon color={selectedPage === 'StaffLeaveRequests' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Request Leave" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedPage('StaffProfile')}>
                <ListItemIcon>
                    <AccountCircleOutlinedIcon color={selectedPage === 'StaffProfile' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Logout')}>
                <ListItemIcon>
                    <ExitToAppIcon color={selectedPage === 'Logout' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </>
    );
}

export default StaffSideBar;