"use client";
import React from "react";
import { Button } from "./MovingBorder";

const ThirdMovingButton = ()=> {

    console.log("box is showin");
  return (
    <div className=" flex flex-col gap-5 ">
        
        <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        Optimize Time
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        Reduce Paperwork
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
        Enhance The Overall Efficiency And Accuracy
      </Button>
      <Button
        borderRadius="1.75rem"
        className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 "
      >
       Better Resource Management
      </Button>
     
    </div>
  );
}
export default ThirdMovingButton;

