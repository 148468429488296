
import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, TextField, Button, Box, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';


const StudentAssignment = ({ standard, section, rollNumber, schoolId, userId , fullName }) => {
  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (standard && section) {
      fetchAssignments();
    }
  }, [standard, section]);

  const fetchAssignments = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`https://api.isnartecherp.online/api/v1/studentAssignment?schoolId=${schoolId}&standard=${standard}&section=${section}`);
      const data = await response.json();
      setAssignments(data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching assignments:', error);
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('assignmentId', selectedAssignment._id);
    formData.append('deadline', selectedAssignment.deadline);
    formData.append('assignmentNumber', selectedAssignment.assignmentNumber);
    formData.append('studentId', userId);
    formData.append('name', fullName);
    formData.append('standard', standard);
    formData.append('section',section);
    formData.append('rollNumber',rollNumber);
    formData.append('schoolId', schoolId);

    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append('file', selectedFiles[i]);
    }

    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/submitAssignment', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (result.success) {
        alert('Assignment submitted successfully!');
        setSelectedFiles([]);
        setSelectedAssignment(null);
      } else {
        alert('Failed to submit assignment');
      }
    } catch (error) {
      console.error('Error submitting assignment:', error);
      alert('Error submitting assignment');
    }
  };

  const downloadFile = async (fileName) => {
    try {
      const response = await fetch(`https://api.isnartecherp.online/api/v1/downloadAssignmentFile?filePath=${encodeURIComponent(fileName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName; // Use the original file name for download
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('Error downloading file');
    }
  };
  
  
 

  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Typography variant="h5" align="center" style={{ backgroundColor: '#5C6BC0', color: '#FFFFFF', padding: '10px', borderRadius: '5px', marginBottom: '20px' }}>
        Student Assignments
      </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper style={{ maxHeight: 300, overflow: 'auto' }}>
              <List>
                {assignments.map((assignment) => {
                  const isDeadlinePast = new Date(assignment.deadline) < new Date();
                  return (
                    <ListItem
                      key={assignment._id}
                      button
                      onClick={() => setSelectedAssignment(assignment)}
                      style={{ backgroundColor: isDeadlinePast ? '#f8d7da' : '#d4edda', marginBottom: '5px' }}
                    >
                      <ListItemText
                        primary={
                          <Box display="flex" justifyContent="space-between">
                            <Typography variant="body1">{assignment.title}</Typography>
                            <Typography variant="body2">{new Date(assignment.deadline).toLocaleDateString()}</Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Paper>
          </Grid>
          {selectedAssignment && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Assignment Details</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  value={selectedAssignment.title}
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  value={selectedAssignment.description}
                  disabled
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Deadline"
                  type="date"
                  value={selectedAssignment.deadline.split('T')[0]}
                  disabled
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Download Assignment Files</Typography>
                {selectedAssignment.assignmentFileUrls.map((fileUrl, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    color="secondary"
                    onClick={() => downloadFile(fileUrl, `Assignment_File_${index + 1}`)}
                    style={{ marginRight: '10px', marginBottom: '10px' }}
                  >
                    Download File {index + 1}
                  </Button>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Submit Assignment</Typography>
                <Box display="flex" alignItems="center">
                  <Button variant="contained" component="label">
                    Upload Files
                    <input type="file" hidden onChange={handleFileChange} multiple />
                  </Button>
                  {selectedFiles.length > 0 && <Typography variant="body2" style={{ marginLeft: '10px', flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedFiles.length} file(s) selected</Typography>}
                </Box>
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Button variant="contained" onClick={handleSubmit} color="primary" style={{ backgroundColor: '#5C6BC0', color: '#FFFFFF' }}>
                  Submit Assignment
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default StudentAssignment;
