


import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, CircularProgress, Container, Grid, Paper, Box, MenuItem } from '@mui/material';
import { PieChart, Pie, Cell, Label } from 'recharts';

const FacultyStudentAttendRecord = ({ classTeacherDetails, schoolId }) => {
    const [attendanceData, setAttendanceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [showDatePickers, setShowDatePickers] = useState(true);
    const [isRecordsFetched, setIsRecordsFetched] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedStandard, setSelectedStandard] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [rollNumber, setRollNumber] = useState('');

    const fetchAttendanceRecords = async () => {
        setLoading(true);
        setError('');
        try {
            // Validate inputs
            if (!selectedStandard || !selectedSection || !rollNumber || !fromDate || !toDate) {
                throw new Error('Please fill in all fields');
            }

            // Simulating API call using fetch
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getAttendance?schoolId=${schoolId}&standard=${selectedStandard}&section=${selectedSection}&rollNumber=${rollNumber}&fromDate=${fromDate}&toDate=${toDate}`);
            if (!response.ok) {
                throw new Error('Failed to fetch attendance records');
            }
            const data = await response.json();
            setAttendanceData(data.attendances);
            setIsRecordsFetched(true);
            setShowDatePickers(false);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (date) => {
        const [year, month, day] = date.split('-');
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    };

    const totalDaysInRange = fromDate && toDate ?
        Math.floor((new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24)) + 1 :
        0;
    const presentCount = attendanceData.filter(item => item.status === "Present").length;
    const absentCount = totalDaysInRange - presentCount;
    const attendancePercentage = totalDaysInRange > 0 ? ((presentCount / totalDaysInRange) * 100).toFixed(2) : 0;

    const pieChartData = [
        { name: 'Present', value: presentCount },
        { name: 'Absent', value: absentCount }
    ];

    const renderAttendanceRecord = (record, index) => (
        <Paper
            elevation={3}
            key={index}
            style={{
                padding: '10px',
                marginBottom: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                border: '1px solid #ccc',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                backgroundColor: index % 2 === 0 ? '#f0f0f0' : '#ffffff'
            }}
        >
            <Typography variant="body1" style={{ marginRight: '20px' }}>{record.fullName}</Typography>
            <Typography variant="body1" style={{ marginRight: '20px' }}>{formatDate(new Date(record.date).toISOString().slice(0, 10))}</Typography>
            <Typography variant="body1">{record.status}</Typography>
        </Paper>
    );

    return (
        <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
            {!isRecordsFetched ? (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Box sx={{ width: '100%', backgroundColor: '#3f51b5', borderRadius: '8px' }}>
                            <Typography
                                variant="h5"
                                align="center"
                                gutterBottom
                                style={{
                                    fontWeight: 500,
                                    color: '#fff',
                                    padding: '8px 0'
                                }}
                            >
                                Check Student Attendance Records
                            </Typography>
                        </Box>
                        <TextField
                            label="Roll Number"
                            value={rollNumber}
                            onChange={(e) => setRollNumber(e.target.value)}
                            fullWidth
                            variant="outlined"
                            style={{ marginBottom: '20px' }}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Select Standard"
                                    select
                                    fullWidth
                                    variant="outlined"
                                    value={selectedStandard}
                                    onChange={(e) => setSelectedStandard(e.target.value)}
                                    style={{ marginBottom: '20px' }}
                                >
                                    {classTeacherDetails.map((item) => (
                                        <MenuItem key={item.standard} value={item.standard}>
                                            {item.standard}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="Select Section"
                                    select
                                    fullWidth
                                    variant="outlined"
                                    value={selectedSection}
                                    onChange={(e) => setSelectedSection(e.target.value)}
                                    style={{ marginBottom: '20px' }}
                                    disabled={!selectedStandard}
                                >
                                    {selectedStandard && classTeacherDetails
                                        .filter((item) => item.standard === selectedStandard)
                                        .map((filteredItem) => (
                                            <MenuItem key={filteredItem.section} value={filteredItem.section}>
                                                {filteredItem.section}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        {showDatePickers ? (
                            <>
                                <TextField
                                    label="From Date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ marginBottom: '20px' }}
                                />
                                <TextField
                                    label="To Date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    type="date"
                                    fullWidth
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    style={{ marginBottom: '20px' }}
                                />
                            </>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => setShowDatePickers(true)}
                                color="primary"
                                fullWidth
                                style={{ marginBottom: '20px', backgroundColor: '#3f51b5', color: '#ffffff' }}
                            >
                                Change Date Range
                            </Button>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={fetchAttendanceRecords}
                                color="primary"
                                style={{
                                    width: 'fit-content',
                                    marginBottom: '20px',
                                    backgroundColor: '#3f51b5',
                                    color: '#ffffff',
                                    textTransform: 'none'
                                }}
                            >
                                Fetch Records
                            </Button>
                        </div>
                        {error && <Typography variant="body1" color="error" align="center">{error}</Typography>}
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            onClick={() => setIsRecordsFetched(false)}
                            color="primary"
                            fullWidth
                            style={{ marginBottom: '20px', backgroundColor: '#3f51b5', color: '#ffffff' }}
                        >
                            Check For Other Date Range
                        </Button>
                        <Typography variant="h6" align="center" gutterBottom>Showing attendance for {formatDate(fromDate)} to {formatDate(toDate)}</Typography>
                        {loading ? (
                            <Grid container justifyContent="center">
                                <Grid item xs={12} style={{ marginTop: '20px' }}>
                                    <CircularProgress />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={2} justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center" gutterBottom>Total Attendance: {presentCount}</Typography>
                                    <Typography variant="h5" align="center" gutterBottom>Attendance Percentage: {attendancePercentage}%</Typography>
                                    <Box display="flex" justifyContent="center" mt={2}>
                                        <PieChart width={400} height={300}>
                                            <Pie
                                                dataKey="value"
                                                data={pieChartData}
                                                cx="50%"
                                                cy="50%"
                                                innerRadius={60}
                                                outerRadius={80}
                                                fill="#8884d8"
                                                label
                                            >
                                                {pieChartData.map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={['#36A2EB', '#FF6384'][index % 2]} />
                                                ))}
                                                <Label
                                                    value="Attendance"
                                                    position="center"
                                                    style={{ fontSize: '20px' }}
                                                />
                                            </Pie>
                                        </PieChart>
                                    </Box>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                        <Button
                                            variant="contained"
                                            onClick={() => setShowDetails(!showDetails)}
                                            color="primary"
                                            style={{
                                                width: 'fit-content',
                                                backgroundColor: '#3f51b5',
                                                color: '#ffffff',
                                                textTransform: 'none',
                                                marginBottom: 10
                                            }}
                                        >
                                            {showDetails ? 'Hide Details' : 'Show Details'}
                                        </Button>
                                    </div>
                                    {showDetails && attendanceData.map((record, index) => (
                                        renderAttendanceRecord(record, index)
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
};

export default FacultyStudentAttendRecord;
