

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar, Drawer } from '../../components/styles';
import SideBar from './SideBar';
import StudentEnrollment from './StudentEnrollment';
import HomePage from './HomePage';
import FacultyEnrollment from './FacultyEnrollment';
import StaffEnrollment from './StaffEnrollment';
import AddSubjects from './AddSubjects';
import Logout from '../Logout';
import AdminProfile from './AdminProfile';
import AllStudents from './AllStudents';
import AllFaculty from './AllFaculty';
import AllStaff from './AllStaff';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import FacultyLeave from './FacultyLeave';
import StaffLeave from './StaffLeave';
import AdminLeaveRequest from './LeaveRequest';

const AdminDashboard = () => {
    const location = useLocation();
    const schoolId = location.state?.schoolId;
    const userId = location.state?._id;    
    const fullName = location.state?.fullName;


    const [open, setOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState('Home');

    console.log(schoolId);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const renderContent = () => {
        switch (selectedPage) {
            case 'Home':
                return <HomePage schoolId={schoolId} />;
            // case 'Classes':
            //     return <ClassesPage />;
            case 'Subjects':
                return <AddSubjects schoolId={schoolId} />;
            case 'Teachers':
                return <FacultyEnrollment schoolId={schoolId} />;
            case 'Students':
                return <StudentEnrollment schoolId={schoolId} selectedPage={selectedPage} />;
            case 'Staff':
                return <StaffEnrollment schoolId={schoolId} />;
            case 'FacultyLeave':
                return <FacultyLeave schoolId={schoolId} />;
            case 'StaffLeave':
                return <StaffLeave schoolId={schoolId} />;
            case 'ViewStudents':
                return <AllStudents schoolId={schoolId} />;
            case 'ViewTeachers':
                return <AllFaculty schoolId={schoolId} />;
            case 'ViewStaff':
                return <AllStaff schoolId={schoolId} />;
                case 'RequestLeave':
                return <AdminLeaveRequest schoolId={schoolId} userId={userId} fullName={fullName} />;
            case 'AdminProfile':
                return <AdminProfile userId={userId} />;
            case 'Logout':
                return <Logout />;
            default:
                return <HomePage />;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} position='absolute'>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Admin Dashboard
                    </Typography>
                    <IconButton
                        color="inherit"
                        component={Link}
                        to="/firstPage"
                        sx={{
                            marginLeft: 'auto', // This will push the button to the right end
                            textDecoration: 'none', // Ensure no underline
                        }}
                    >
                        <HomeIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={open ? styles.drawerStyled : styles.hideDrawer}>
                <Toolbar sx={styles.toolBarStyled}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <SideBar setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
                </List>
            </Drawer>
            <Box component="main" sx={styles.boxStyled}>
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}

export default AdminDashboard

const styles = {
    boxStyled: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: '20px'
    },
    toolBarStyled: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
    },
    drawerStyled: {
        display: "flex"
    },
    hideDrawer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
}
