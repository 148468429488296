
import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Container,
  Box,
  CircularProgress
} from '@mui/material';
import toast from 'react-hot-toast';

const FacultyAttendance = ({ classTeacherDetails, schoolId }) => {
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedStandard, setSelectedStandard] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [showInputs, setShowInputs] = useState(true);
  
  const [isFetchingStudents, setIsFetchingStudents] = useState(false);
  const [updatingStudents, setUpdatingStudents] = useState({}); // { [studentId]: true }

  useEffect(() => {
    const currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const currentTime = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    setDate(currentDate);
    setTime(currentTime);
  }, []);

  const fetchStudents = async () => {
    if (!selectedStandard || !selectedSection) {
      toast.error('Please select both standard and section.');
      return;
    }
    
    setIsFetchingStudents(true);
    try {
      const response = await fetch(`https://api.isnartecherp.online/api/v1/getStudentsByClass?schoolId=${schoolId}&standard=${selectedStandard}&section=${selectedSection}`);
      if (!response.ok) {
        throw new Error('Failed to fetch students');
      }
      const data = await response.json();
      const studentsWithStatus = data.students.map(student => ({
        ...student,
        status: 'Absent'
      }));
      setStudents(studentsWithStatus);
      setShowInputs(false);
    } catch (error) {
      console.error('Error fetching students:', error);
      toast.error('Failed to fetch students. Please try again.');
    } finally {
      setIsFetchingStudents(false);
    }
  };

  const markAttendance = async (studentId, fullName, rollNumber, index) => {
    setUpdatingStudents(prev => ({ ...prev, [studentId]: true }));
    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/createAttendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          studentId: studentId,
          schoolId: schoolId,
          standard: selectedStandard,
          section: selectedSection,
          fullName: fullName,
          rollNumber: rollNumber,
          status: 'Present'
        })
      });
      if (!response.ok) {
        throw new Error('Failed to mark attendance');
      }
      const updatedStudents = [...students];
      updatedStudents[index].status = 'Present';
      setStudents(updatedStudents);
      toast.success(`Marked ${fullName} as Present`);
    } catch (error) {
      console.error('Error marking attendance:', error);
      toast.error('Failed to mark attendance. Please try again.');
    } finally {
      setUpdatingStudents(prev => {
        const newUpdating = { ...prev };
        delete newUpdating[studentId];
        return newUpdating;
      });
    }
  };

  const deleteAttendance = async (studentId, rollNumber, index) => {
    setUpdatingStudents(prev => ({ ...prev, [studentId]: true }));
    try {
      // Assuming PUT is the required method. If DELETE is correct, change it accordingly.
      const response = await fetch(`https://api.isnartecherp.online/api/v1/deleteAttendance/${studentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          schoolId: schoolId,
          standard: selectedStandard,
          section: selectedSection,
          rollNumber: rollNumber,
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete attendance');
      }

      // Update the local students array to set the status of the student to 'Absent'
      const updatedStudents = students.map((student, idx) =>
        idx === index ? { ...student, status: 'Absent' } : student
      );
      setStudents(updatedStudents);
      toast.success("Present status removed successfully");
    } catch (error) {
      console.error('Error deleting attendance:', error);
      toast.error('Error occurred while removing attendance');
    } finally {
      setUpdatingStudents(prev => {
        const newUpdating = { ...prev };
        delete newUpdating[studentId];
        return newUpdating;
      });
    }
  };

  const handleChangeStandard = (standard) => {
    setSelectedStandard(standard);
    setShowInputs(true);
    setStudents([]);
  };

  const handleChangeSection = (section) => {
    setSelectedSection(section);
    setShowInputs(true);
    setStudents([]);
  };

  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Typography
        variant="h5"
        align="center"
        style={{
          backgroundColor: '#5C6BC0',
          color: '#FFFFFF',
          padding: '10px',
          borderRadius: '5px',
          marginBottom: '20px'
        }}
      >
        Attendance for {date} - {time}
      </Typography>
      {showInputs ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Select Standard</InputLabel>
              <Select
                value={selectedStandard}
                onChange={(e) => handleChangeStandard(e.target.value)}
              >
                <MenuItem value="">
                  {selectedStandard ? "Change Standard" : "Select Standard"}
                </MenuItem>
                {classTeacherDetails && classTeacherDetails.map(({ standard }) => (
                  <MenuItem key={standard} value={standard}>{standard}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Select Section</InputLabel>
              <Select
                value={selectedSection}
                onChange={(e) => handleChangeSection(e.target.value)}
              >
                <MenuItem value="">
                  {selectedSection ? "Change Section" : "Select Section"}
                </MenuItem>
                {classTeacherDetails && classTeacherDetails.map(({ section }) => (
                  <MenuItem key={section} value={section}>{section}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box position="relative" display="inline-flex">
              <Button
                variant="contained"
                onClick={fetchStudents}
                color="primary"
                disabled={isFetchingStudents}
                fullWidth
              >
                {isFetchingStudents ? 'Taking Attendance...' : 'Take Attendance'}
              </Button>
              {isFetchingStudents && (
                <CircularProgress
                  size={24}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          mt={2}
          mb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box position="relative" display="inline-flex" mr={2}>
            <Button
              variant="contained"
              onClick={() => setShowInputs(true)}
              color="primary"
              style={{ backgroundColor: '#5C6BC0', color: '#FFFFFF' }}
            >
              Change Selection
            </Button>
          </Box>
          <Typography
            variant="body1"
            style={{
              backgroundColor: '#5C6BC0',
              color: '#FFFFFF',
              padding: '7px',
              borderRadius: '5px'
            }}
          >
            Total Students: {students.length}
          </Typography>
        </Box>
      )}
      {students.length === 0 ? (
        <Typography variant="body1" fontStyle="italic" color="textSecondary">
          No data available
        </Typography>
      ) : (
        <Container maxWidth="lg" style={{ marginTop: '20px' }}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            {students.map((student, index) => (
              <Grid
                item
                key={student._id}
                style={{
                  width: '100%',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '10px',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#FFFFFF',
                  marginBottom: '10px'
                }}
              >
                <Typography variant="body1">{student.fullName}</Typography>
                <Typography variant="body2">Roll Number: {student.rollNumber}</Typography>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '10px',
                    width: '100%'
                  }}
                >
                  <Typography variant="body2" color="primary">
                    {student.status}
                  </Typography>
                  {student.status === 'Absent' ? (
                    <Box position="relative" display="inline-flex">
                      <Button
                        variant="contained"
                        onClick={() => markAttendance(student._id, student.fullName, student.rollNumber, index)}
                        color="primary"
                        disabled={!!updatingStudents[student._id]}
                      >
                        {updatingStudents[student._id] ? 'Marking...' : 'Mark Present'}
                      </Button>
                      {updatingStudents[student._id] && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12,
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <Box position="relative" display="inline-flex">
                      <Button
                        variant="contained"
                        onClick={() => deleteAttendance(student._id, student.rollNumber, index)}
                        color="secondary"
                        disabled={!!updatingStudents[student._id]}
                      >
                        {updatingStudents[student._id] ? 'Removing...' : 'Remove Present'}
                      </Button>
                      {updatingStudents[student._id] && (
                        <CircularProgress
                          size={24}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12,
                          }}
                        />
                      )}
                    </Box>
                  )}
                </div>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </Container>
  );
};

export default FacultyAttendance;

