import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem, IconButton, FormControl, InputLabel, Grid, Box, Typography } from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import toast from 'react-hot-toast';

const AddSubjects = ({schoolId}) => {
  const [courseData, setCourseData] = useState({
    standard: '',
    schoolId: schoolId,
    subjects: [{ subjectCode: '', subjectName: '' }],
  });

  const [firstAdd, setFirstAdd] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCourseData({ ...courseData, [name]: value });
  };

  const handleSubjectChange = (index, event) => {
    const { name, value } = event.target;
    const subjects = [...courseData.subjects];
    subjects[index][name] = value;
    setCourseData({ ...courseData, subjects });
  };

  const handleAddSubject = () => {
    if (firstAdd) {
      setFirstAdd(false);
    }
    setCourseData({ ...courseData, subjects: [...courseData.subjects, { subjectCode: '', subjectName: '' }] });
  };

  const handleRemoveSubject = (index) => {
    const subjects = [...courseData.subjects];
    subjects.splice(index, 1);
    setCourseData({ ...courseData, subjects });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/createCourse', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(courseData),
      });
      if (!response.ok) {
        throw new Error('Failed to add course');
      }
      setCourseData({
        standard: '',
        schoolId: '',
        subjects: [{ subjectCode: '', subjectName: '' }],
      });
      toast.success('Course added successfully');
    } catch (error) {
      console.error('Error adding course:', error.message);
      toast.error('Failed to add course');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box maxWidth="md" margin="auto" bgcolor="#f4f4f4" padding={3} borderRadius={8}>
        <Typography variant="h5" align="center" gutterBottom style={{ fontWeight: 500, backgroundColor: '#3f51b5', color: '#fff', padding: '10px 0', borderRadius: '8px' }}>
          Add Subjects for Standard
        </Typography>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Standard</InputLabel>
              <Select
                value={courseData.standard}
                onChange={handleChange}
                name="standard"
                required
                variant="outlined"
              >
                {['L.K.G', 'U.K.G', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={12}>
            <TextField
              label="School ID"
              name="schoolId"
              value={courseData.schoolId}
              onChange={handleChange}
              fullWidth
              required
              variant="outlined"
            />
          </Grid> */}

          {courseData.subjects.map((subject, index) => (
            <React.Fragment key={index}>
              <Grid item xs={6}>
                <TextField
                  label="Subject Code"
                  name="subjectCode"
                  value={subject.subjectCode}
                  onChange={(event) => handleSubjectChange(index, event)}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Subject Name"
                  name="subjectName"
                  value={subject.subjectName}
                  onChange={(event) => handleSubjectChange(index, event)}
                  fullWidth
                  required
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex">
                  {index === courseData.subjects.length - 1 && (
                    <IconButton onClick={handleAddSubject} style={{ backgroundColor: '#70c1b3', marginRight: 8 }}>
                      <AddIcon />
                    </IconButton>
                  )}
                  {index !== 0 && (
                    <IconButton onClick={() => handleRemoveSubject(index)} style={{ backgroundColor: '#ff6f61' }}>
                      <RemoveIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Button type="submit" variant="contained" color="primary">
                Submit Details
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default AddSubjects;
