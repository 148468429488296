import React, { useState } from 'react';
import axios from 'axios';
import { Container, Typography, TextField, Button, Grid, InputLabel, FormControl, Select, MenuItem, FormHelperText, Box, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
const LeaveRequest = (props) => {
    const { schoolId, userId, fullName, classTeacherDetails } = props;

    const [leaveType, setLeaveType] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [reason, setReason] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!leaveType || !startDate || !endDate || !reason || !attachment) {
            setError('All fields are required.');
            return;
        }

        const formData = new FormData();
        formData.append('schoolId', schoolId);
        formData.append('userId', userId);
        formData.append('fullName', fullName);
        formData.append('classTeacherDetails', JSON.stringify(classTeacherDetails));
        formData.append('leaveType', leaveType);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('reason', reason);                
        formData.append('attachment', attachment);

        setLoading(true);
        try {
            await axios.post('https://api.isnartecherp.online/api/v1/submitLeaveRequest', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success('Leave request submitted successfully.');

            // reseting..
            setLeaveType('');
            setStartDate('');
            setEndDate('');
            setReason('');
            setAttachment([]);

        } catch (err) {
            toast.error('Failed to submit leave request.');
            setLoading(false);

        } finally {
            setLoading(false);
        }
    };

    return (
        <Container component="main" maxWidth="md">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    backgroundColor: '#f9f9f9',
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        backgroundColor: '#1f618d',
                        color: 'white',
                        padding: '8px',
                        borderRadius: '4px',
                        width: '100%',
                        boxSizing: 'border-box',
                        textAlign: 'center'
                    }}
                >
                    Request Leave
                </Typography>

                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth required>
                                <InputLabel id="leave-type-label">Leave Type</InputLabel>
                                <Select
                                    labelId="leave-type-label"
                                    id="leave-type"
                                    value={leaveType}
                                    onChange={(e) => setLeaveType(e.target.value)}
                                    label="Leave Type"
                                >
                                    <MenuItem value="Sick Leave">Sick Leave</MenuItem>
                                    <MenuItem value="Casual Leave">Casual Leave</MenuItem>
                                    <MenuItem value="Vacation">Vacation</MenuItem>
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Start Date"
                                type="date"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="End Date"
                                type="date"
                                fullWidth
                                required
                                InputLabelProps={{ shrink: true }}
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Reason"
                                multiline
                                rows={4}
                                fullWidth
                                required
                                value={reason}
                                onChange={(e) => setReason(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} container alignItems="center">
                            <Button
                                variant="contained"
                                component="label"
                                sx={{ textTransform: 'none' }} // Add textTransform: 'none'
                            >
                                Upload Attachment
                                <input
                                    type="file"
                                    hidden
                                    onChange={(e) => setAttachment(e.target.files[0])}
                                />
                            </Button>
                            {attachment && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginLeft: '10px',
                                        flexGrow: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {attachment.name} {/* Show file name */}
                                </Typography>
                            )}
                        </Grid>

                    </Grid>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"

                    >
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    textTransform: 'none',
                                    fontSize: 16,
                                    backgroundColor: '#1f618d',
                                    ':hover': {
                                        backgroundColor: '#2471a3',
                                    },
                                }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} /> : 'Submit Request'}
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Container>
    );
};

export default LeaveRequest;