import React from 'react';
import { Container, Grid, Box, Typography, IconButton } from '@mui/material';
import { Facebook, WhatsApp } from '@mui/icons-material';

const Footer = ({ schoolData }) => {
  return (
    <Box mt={5} py={3} bgcolor="#f5f5f5" color="#666" textAlign="center">
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">
              Email: {schoolData.email} <br />
              Address: {schoolData.address}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <IconButton href="https://www.facebook.com" target="_blank">
              <Facebook />
            </IconButton>
            <IconButton href="https://wa.me/1234567890" target="_blank">
              <WhatsApp />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
