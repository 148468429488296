import React, { useState } from 'react';
import { CircularProgress, Container, TextField, Button, Typography, MenuItem, Grid, Box } from '@mui/material';
import toast from "react-hot-toast";

const StaffEnrollment = ({ schoolId, selectedPage }) => {
    const [userType, setUserType] = useState('staff');
    const [fullName, setFullName] = useState('');
    const [aadhaarNumber, setAadhaarNumber] = useState('');
    const [address, setAddress] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loader, setLoader] = useState(false);

    const submitHandler = async (event) => {
        event.preventDefault();
        setLoader(true);

        // Check if password and confirmPassword match
        if (password !== confirmPassword) {
            toast.error('Password and Confirm Password do not match');
            setLoader(false); // Reset loader state
            return; // Prevent form submission
        }

        const staffData = {
            userType,
            fullName,
            aadhaarNumber,
            address,
            email,
            password,
            confirmPassword,
            schoolId
        };

        try {
            const response = await fetch('https://api.isnartecherp.online/api/v1/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(staffData),
            });

            if (response.ok) {
                // Reset form data after successful submission             
                setFullName('');
                setAadhaarNumber('');
                setAddress('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');

                // Show success toast
                toast.success('Form submitted successfully');
            } else {
                throw new Error('Failed to submit form');
            }
        } catch (error) {
            // Show error toast
            toast.error('Failed to submit form');
            console.error('Error submitting form:', error);
        } finally {
            setLoader(false);
        }
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Box sx={{ width: '100%', backgroundColor: '#3f51b5', borderRadius: '8px' }}>
                    <Typography
                        variant="h5"
                        align="center"
                        gutterBottom
                        style={{
                            fontWeight: 500,
                            color: '#fff',
                            padding: '8px 0'
                        }}
                    >
                        Enroll Staff
                    </Typography>
                </Box>
                <form onSubmit={submitHandler} style={{ width: '100%', marginTop:16 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="User Type"
                                fullWidth
                                value={userType}
                                onChange={(event) => setUserType(event.target.value)}
                                disabled // Disable editing
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label="Full Name"
                                fullWidth
                                value={fullName}
                                onChange={(event) => setFullName(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Aadhaar Number"
                                fullWidth
                                value={aadhaarNumber}
                                onChange={(event) => setAadhaarNumber(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Address"
                                fullWidth
                                value={address}
                                onChange={(event) => setAddress(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                fullWidth
                                type="email"
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                fullWidth
                                type="password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Confirm Password"
                                fullWidth
                                type="password"
                                value={confirmPassword}
                                onChange={(event) => setConfirmPassword(event.target.value)}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" width="100%" marginTop={3}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loader}
                            >
                                {loader ? <CircularProgress size={24} color="inherit" /> : 'Enroll'}
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

export default StaffEnrollment;
