

// export default ContactForm;

import React, { useState } from "react";
import "./ContactForm.css";
import { CgMail } from "react-icons/cg";
import { BsSend } from "react-icons/bs";
import { BiLogoGmail } from "react-icons/bi";
import { AiFillTwitterCircle, AiFillLinkedin, AiFillFacebook } from "react-icons/ai";
import toast from "react-hot-toast";
import ErpNavbar from "../ErpNavbar";
import { CircularProgress } from "@mui/material";

const ContactForm = () => {
    const [formdata, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        schoolName: "",
        message: "",
    });
    const [loading, setLoading] = useState(false);

    const changeHandler = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            [event.target.name]: event.target.value,
        }));
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await fetch("https://api.isnartecherp.online/api/v1/createContact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formdata),
            });

            if (!response.ok) {
                throw new Error("Failed to send message");
            }

            const responseData = await response.json();
            console.log(responseData); // Handle response data as needed

            toast.success("Message sent successfully!");
            setFormData({
                name: "",
                email: "",
                phone: "",
                schoolName: "",
                message: "",
            });
        } catch (error) {
            console.error("Error sending message:", error);
            toast.error("Failed to send message. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="w-full h-full ">
            <ErpNavbar />
            <div className="text-white">
                <div className="contact relative">
                    <div className="relative top-[5rem]">
                        <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold text-center bg-gradient-to-r from-cyan-500 to-emerald-400 text-transparent bg-clip-text">
                            Get In Touch
                        </h2>
                        <h3 className="text-[20px] font-inter text-center font-medium bg-gradient-to-r from-cyan-500 to-emerald-400 text-transparent bg-clip-text">
                            We solve your queries as soon as possible..
                        </h3>
                    </div>
                </div>

                <div className="contact-responsive flex flex-col md:flex-row w-full md:w-[70%] mx-auto justify-around bg-gradient-to-r from-blue-900 to-teal-900 relative -top-[125px]">
                    {/* Form Section */}
                    <div className="flex flex-col w-full gap-20 md:w-2/3">
                        <div className="flex mt-[30px] justify-between items-center">
                            <h2 className="text-[25px] font-semibold font-inter ml-8">
                                Send us a Message
                            </h2>
                            <CgMail className="text-[30px] mr-7" />
                        </div>

                        <form onSubmit={submitHandler}>
                            <div className="input-container">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        required
                                        name="name"
                                        value={formdata.name}
                                        onChange={changeHandler}
                                        className="input-data"
                                        id="name"
                                    />
                                    <div className="underline-text relative"></div>
                                    <label htmlFor="name">Your Name</label>
                                </div>

                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        required
                                        autoComplete="off"
                                        name="email"
                                        value={formdata.email}
                                        onChange={changeHandler}
                                        className="input-data"
                                        id="email"
                                    />
                                    <div className="underline-text"></div>
                                    <label htmlFor="email">Email Address</label>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="input-wrapper">
                                    <input
                                        type="tel"
                                        required
                                        autoComplete="off"
                                        name="phone"
                                        value={formdata.phone}
                                        onChange={changeHandler}
                                        id="phone"
                                        className="input-data"
                                    />
                                    <div className="underline-text"></div>
                                    <label htmlFor="phone">Phone Number</label>
                                </div>

                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        required
                                        autoComplete="off"
                                        name="schoolName"
                                        value={formdata.schoolName}
                                        onChange={changeHandler}
                                        id="schoolName"
                                        className="input-data"
                                    />
                                    <div className="underline-text"></div>
                                    <label htmlFor="schoolName">School Name</label>
                                </div>
                            </div>

                            <div className="input-container">
                                <div className="input-wrapper">
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        required
                                        name="message"
                                        value={formdata.message}
                                        onChange={changeHandler}
                                        className="input-data"
                                        id="message"
                                    />
                                    <div className="underline-text"></div>
                                    <label htmlFor="message">Message</label>
                                </div>
                            </div>

                            <div className="btn">
                                <button type="submit" disabled={loading}>
                                    {loading ? <CircularProgress /> : "Submit"}
                                </button>
                                <span>
                                    {loading ? '' : <BsSend />}
                                </span>
                            </div>
                        </form>
                    </div>

                    {/* Information Section (Hidden on Small Screens) */}
                    <div className="information w-[23rem] bg-[#4158d0] hidden md:block">
                        <h2 className="contact-text">Contact information</h2>

                        <div className="gmail-info">
                            <div className="gmail-logo">
                                <BiLogoGmail className="text-[35px]" />
                            </div>
                            <div className="mt-4 gmail">enquiries@isnartech.com</div>
                        </div>

                        <div className="flex gap-4 mb-4">
                            <span className="text-[25px] border rounded-md">
                                <AiFillLinkedin />
                            </span>
                            <span className="text-[25px] border rounded-md">
                                <AiFillTwitterCircle />
                            </span>
                            <span className="text-[25px] border rounded-md">
                                <AiFillFacebook />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {/* <HomeFooter /> */}
        </div>
    );
};

export default ContactForm;

