import React from "react";
import { CardBody, CardContainer, CardItem } from "./3d-card";
import MovingBorderBox from "./MovingBorder-comp";
import { Grid } from '@mui/material';
import SecMovingButton from "./SecMovingBtn";
import ThirdMovingButton from "./ThirdBtnCard";
import { Link } from "react-router-dom";

const ThreeDCardDemo = () => {
  return (
    <Grid container spacing={22} className="flex justify-between ">
      {/* First Card */}

      {/* Second Card */}
      <Grid item xs={12} sm={6}>
        <CardContainer className="inter-var ">
          <CardBody className="bg-[#34495E] relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border">
            <CardItem
              translateZ="50"
              className="text-xl font-bold text-neutral-600 dark:text-white text-[#ffffff]"
            >
              What sets Isnartech School ERP stand out from other.
            </CardItem>
            <div className=" mt-[1rem]">
              <SecMovingButton />
            </div>
            <div className="flex justify-between items-center mt-20">
              <Link to="/registerSchool" target="__blank" className="px-4 py-2 rounded-xl text-[15px] font-normal dark:text-white text-[#ffffff]">
                <CardItem translateZ="20">
                  Try Our ERP Solution →
                </CardItem>
              </Link>
            </div>
          </CardBody>
        </CardContainer>
      </Grid>

      {/* third card */}
      <Grid item xs={12} sm={6}>
        <CardContainer className="inter-var">
          <CardBody className="bg-[#34495E] border relative group/card dark:hover:shadow-2xl dark:hover:shadow-emerald-500/[0.1] dark:bg-black dark:border-white/[0.2] border-black/[0.1] w-auto sm:w-[30rem] h-auto rounded-xl p-6 border">
            <CardItem
              translateZ="50"
              className="text-xl font-bold text-neutral-600 dark:text-white text-[#ffffff]"
            >
              Benefits To Use Isnartech ERP Solution.
            </CardItem>
            <div className=" mt-[1rem]">
              <ThirdMovingButton />
            </div>

            <div className="flex justify-between items-center mt-20">
              <Link to="/registerSchool" target="__blank" className="px-4 py-2 rounded-xl text-[15px] font-normal dark:text-white text-[#ffffff]">
                <CardItem translateZ="20">
                  Try Our ERP Solution →
                </CardItem>
              </Link>
            </div>

          </CardBody>
        </CardContainer>
      </Grid>
    </Grid>
  );
}

export default ThreeDCardDemo;
