

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, CircularProgress } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-hot-toast'; // Updated import for react-hot-toast

const FacultyLeave = ({ schoolId }) => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingRequestId, setLoadingRequestId] = useState(null); // Track the currently loading request

  useEffect(() => {
    fetchLeaveRequests();
  }, [schoolId]);

  const fetchLeaveRequests = async () => {
    try {
      const response = await axios.get(`https://api.isnartecherp.online/api/v1/getFacultyLeaveRequest?schoolId=${schoolId}`);
      setLeaveRequests(response.data.allFacultyLeave || []);
    } catch (error) {
      console.error('Error fetching leave requests:', error);
      toast.error('Error fetching leave requests'); // Show error toast
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (fileName) => {
    setLoadingRequestId(fileName); // Set loading state for the download action
    try {
      const response = await fetch(`https://api.isnartecherp.online/api/v1/downloadFacultyLeaveFile?filePath=${encodeURIComponent(fileName)}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName; // Use the original file name for download
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.error('Error downloading file'); // Show error toast
    } finally {
      setLoadingRequestId(null); // Reset loading state after download
    }
  };

  const handleApprove = async (requestId) => {
    setLoadingRequestId(requestId); // Set loading state for the approval action
    try {
      const response = await axios.put(`https://api.isnartecherp.online/api/v1/approveLeaveRequest/${requestId}`, {
        schoolId // Include schoolId in the request payload
      });
      if (response.status === 200) {
        fetchLeaveRequests(); // Refresh the list of leave requests
        toast.success('Request approved successfully');
        setLoadingRequestId(null);
      } else {
        console.error('Error approving request:', response.data);
        toast.error('Error approving request'); // Show error toast
      }
    } catch (error) {
      console.error('Error approving request:', error);
      toast.error('Error approving request'); // Show error toast
    } finally {
      setLoadingRequestId(null); // Reset loading state after approval
    }
  };

  // Function to extract class details
  const extractClassDetails = (classTeacherDetails) => {
    let allCourses = [];
    
    // Check if classTeacherDetails is a JSON string and parse it if needed
    if (Array.isArray(classTeacherDetails) && typeof classTeacherDetails[0] === 'string') {
      try {
        const parsedData = JSON.parse(classTeacherDetails[0]);
        if (Array.isArray(parsedData)) {
          parsedData.forEach(detail => {
            if (detail && detail.standard !== undefined && detail.section !== undefined) {
              allCourses.push({
                standard: detail.standard,
                section: detail.section
              });
            }
          });
        }
      } catch (error) {
        console.error('Error parsing classTeacherDetails:', error);
      }
    } else if (Array.isArray(classTeacherDetails)) {
      // Handle case where it's already an array of objects
      classTeacherDetails.forEach(detail => {
        if (detail && detail.standard !== undefined && detail.section !== undefined) {
          allCourses.push({
            standard: detail.standard,
            section: detail.section
          });
        }
      });
    }

    return allCourses;
  };

  if (loading) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Typography variant="h6" gutterBottom style={{ fontWeight: '600' }}>Teacher Leave Requests</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Full Name</TableCell>
                  <TableCell>Class Details</TableCell>
                  <TableCell>Leave Type</TableCell>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Application</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequests.map((request) => {
                  const classDetails = extractClassDetails(request.classTeacherDetails);
                  return (
                    <TableRow key={request._id}>
                      <TableCell>{request.fullName}</TableCell>
                      <TableCell>
                        {classDetails.length > 0 ? (
                          classDetails.map((detail, index) => (
                            <div key={index}>
                              {detail.standard} - {detail.section}
                            </div>
                          ))
                        ) : (
                          <Typography>No class details</Typography>
                        )}
                      </TableCell>
                      <TableCell>{request.leaveType}</TableCell>
                      <TableCell>{new Date(request.startDate).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(request.endDate).toLocaleDateString()}</TableCell>
                      <TableCell>{request.reason}</TableCell>
                      <TableCell>
                        <Button
                          startIcon={<DownloadIcon />}
                          variant="contained"
                          color="primary"
                          onClick={() => downloadFile(request.attachmentUrl)}
                          style={{ textTransform: 'none' }} // Prevent text from transforming to uppercase
                          disabled={loadingRequestId === request.attachmentUrl} // Disable button while loading
                        >
                          {loadingRequestId === request.attachmentUrl ? <CircularProgress size={24} /> : 'Download'}
                        </Button>
                      </TableCell>
                      <TableCell>    
                        <Button
                          variant="contained"
                          color={request.approved ? 'primary' : 'success'} // Change color based on approval status
                          onClick={() => !request.approved && handleApprove(request._id)}
                          style={{ textTransform: 'none' }} // Prevent text from transforming to uppercase
                          disabled={loadingRequestId === request._id} // Disable button while loading
                        >
                          {loadingRequestId === request._id ? <CircularProgress size={24} /> : (request.approved ? 'Approved' : 'Approve')}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FacultyLeave;

