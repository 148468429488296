

import React, { useState } from 'react';
import {
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,

    List,
    Collapse,
} from '@mui/material';
import HomeIcon from "@mui/icons-material/Home";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';

const SideBar = ({ setSelectedPage, selectedPage }) => {
    const [openViewUsers, setOpenViewUsers] = useState(false);
    const [openViewLeave, setOpenViewLeave] = useState(false);

    const handleViewUsersClick = () => {
        setOpenViewUsers(!openViewUsers);
    };

    const handleViewLeaveClick = () => {
        setOpenViewLeave(!openViewLeave);
    };
    return (
        <>
            <ListItemButton onClick={() => setSelectedPage('Home')}>
                <ListItemIcon>
                    <HomeIcon color={selectedPage === 'Home' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Home" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedPage('Admin')}>
                <ListItemIcon>
                    <SupervisorAccountOutlinedIcon color={selectedPage === 'Admin' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Enroll Admin" />
            </ListItemButton>

            <ListItemButton onClick={() => setSelectedPage('Students')}>
                <ListItemIcon>
                    <PersonOutlineIcon color={selectedPage === 'Students' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Enroll Students" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Teachers')}>
                <ListItemIcon>
                    <SupervisorAccountOutlinedIcon color={selectedPage === 'Teachers' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Enroll Teachers" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Staff')}>
                <ListItemIcon>
                    <SupervisorAccountOutlinedIcon color={selectedPage === 'Staff' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Enroll Staff" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Subjects')}>
                <ListItemIcon>
                    <AssignmentIcon color={selectedPage === 'Subjects' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Add Subjects" />
            </ListItemButton>


            {/* View Users Section */}
            <ListItemButton onClick={handleViewUsersClick}>
                <ListItemIcon>
                    <AccountCircleOutlinedIcon color={openViewUsers ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="View Users" />
                {openViewUsers ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openViewUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton onClick={() => setSelectedPage('ViewStudents')}>
                        <ListItemIcon>
                            <PersonOutlineIcon color={selectedPage === 'ViewStudents' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="View Students" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('ViewTeachers')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'ViewTeachers' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="View Teachers" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('ViewStaff')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'ViewStaff' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="View Staff" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('ViewAdmin')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'ViewAdmin' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="View Admin" />
                    </ListItemButton>

                </List>
            </Collapse>

            {/* view leave request of user */}

            <ListItemButton onClick={handleViewLeaveClick}>
                <ListItemIcon>
                    <HolidayVillageIcon color={openViewLeave ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="View Leave" />
                {openViewLeave ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openViewLeave} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton onClick={() => setSelectedPage('ViewStudentsLeaveReq')}>
                        <ListItemIcon>
                            <PersonOutlineIcon color={selectedPage === 'ViewStudentsLeaveReq' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Students Leave" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('ViewTeachersLeaveReq')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'ViewTeachersLeaveReq' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Teachers Leave" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('ViewStaffsLeaveReq')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'ViewStaffsLeaveReq' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Staffs Leave" />
                    </ListItemButton>
                    <ListItemButton onClick={() => setSelectedPage('AdminLeave')}>
                        <ListItemIcon>
                            <SupervisorAccountOutlinedIcon color={selectedPage === 'AdminLeave' ? 'primary' : 'inherit'} />
                        </ListItemIcon>
                        <ListItemText primary="Admins Leave" />
                    </ListItemButton>


                </List>
            </Collapse>

            {/* Other Sidebar Items */}



            <Divider sx={{ my: 1 }} />
            <ListSubheader component="div" inset>
                User
            </ListSubheader>
            <ListItemButton onClick={() => setSelectedPage('AdminProfile')}>
                <ListItemIcon>
                    <AccountCircleOutlinedIcon color={selectedPage === 'AdminProfile' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton onClick={() => setSelectedPage('Logout')}>
                <ListItemIcon>
                    <ExitToAppIcon color={selectedPage === 'Logout' ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </>
    );
}

export default SideBar;



