

import React, { useEffect, useState } from 'react';
import { Container, Typography, TextField, Button, Grid, CircularProgress, Box } from '@mui/material';
import toast from 'react-hot-toast';

const StudentProfile = ({ schoolId, userId }) => {
    const [student, setStudent] = useState(null);
    const [editable, setEditable] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchStudent();
    }, []);

    const fetchStudent = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getStudentById?userId=${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch student detail');
            }
            const data = await response.json();
            console.log(data);
            if (data && data.student && data.student.length > 0) {
                setStudent(data.student[0]);
            } else {
                throw new Error('Student not found');
            }
        } catch (error) {
            console.error('Error fetching student detail:', error);
            toast.error('Failed to fetch student details');
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateStudent/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fullName: student.fullName,
                    aadhaarNumber: student.aadhaarNumber,
                    address: student.address,
                    email: student.email,
                }),
            });
            if (!response.ok) {
                throw new Error('Failed to update student');
            }
            toast.success('Profile updated successfully');
            setEditable(false);
        } catch (error) {
            console.error('Error updating student:', error);
            toast.error('Failed to update profile');
        }
    };

    const handleChange = (prop) => (event) => {
        setStudent({ ...student, [prop]: event.target.value });
    };

    if (loading) {
        return (
            <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
                <CircularProgress />
            </Container>
        );
    }

    if (!student) {
        return (
            <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h6">No student data available.</Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" style={{ padding: '20px', marginTop: '20px' }}>
            <Box sx={{ backgroundColor: '#3f51b5', borderRadius: '8px', marginBottom: '20px' }}>
                <Typography
                    variant="h5"
                    align="center"
                    gutterBottom
                    style={{
                        fontWeight: 500,
                        color: '#fff',
                        padding: '8px 0',
                        margin: '0 auto', // Center align within the container
                        maxWidth: '100%', // Ensure it spans the full width of the container
                    }}
                >
                   Update Your Profile
                </Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Full Name"
                        value={student.fullName}
                        onChange={handleChange('fullName')}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Aadhaar Number"
                        value={student.aadhaarNumber}
                        onChange={handleChange('aadhaarNumber')}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Address"
                        value={student.address}
                        onChange={handleChange('address')}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        value={student.email}
                        onChange={handleChange('email')}
                        disabled={!editable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Standard"
                        value={student.standard}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Section"
                        value={student.section}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Roll Number"
                        value={student.rollNumber}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={editable ? handleSave : () => setEditable(true)}>
                        {editable ? 'Save' : 'Edit'}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default StudentProfile;
