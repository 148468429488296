"use client";
import { TextGenerateEffect } from "./TextGenerate";

const words = `In today’s fast-paced educational environment, efficient management of school operations is paramount. The Isnartech School ERP Management System stands at the forefront of this transformation, offering a comprehensive and intuitive solution designed to streamline and enhance the administrative processes of educational institutions.
Isnartech School ERP is an integrated platform that brings together various school functions under one roof. This modern technology ensures that administrators, teachers, students, and parents can easily access and manage essential information.
`;

const TextGenerateEffecWord = ()=>{
  return <TextGenerateEffect words={words} />;
}
export default TextGenerateEffecWord;
