

// import React, { useContext, useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import {
//   Grid,
//   Paper,
//   Typography,
//   TextField,
//   CircularProgress,
//   Backdrop,
//   CssBaseline,
//   Box,
//   styled,
//   IconButton,
//   InputAdornment,
//   FormControlLabel,
//   Checkbox,
// } from '@mui/material';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { LightPurpleButton } from '../components/buttonStyles';
// import toast from "react-hot-toast";
// import { AuthContext } from './context/AuthContext';
// import { Visibility, VisibilityOff } from '@mui/icons-material';

// // Import background image (replace with your actual image import)
// import bgpic from "../assets/designlogin.jpg";

// const defaultTheme = createTheme();

// const StyledLink = styled(RouterLink)(({ theme }) => ({
//   color: theme.palette.primary.main,
//   textDecoration: 'none',
//   '&:hover': {
//     textDecoration: 'underline',
//   },
// }));

// const LoginPage = () => {
//   const navigate = useNavigate();
//   const { loginByPassword, sendOTP, administratorLogin } = useContext(AuthContext);

//   const [toggle, setToggle] = useState(false);
//   const [loader, setLoader] = useState(false);
//   const [emailError, setEmailError] = useState(false);
//   const [passwordError, setPasswordError] = useState(false);
//   const [otpMode, setOtpMode] = useState(false); // Track if OTP mode is active
//   const [otpEmail, setOtpEmail] = useState(''); // Track the email for OTP mode
//   const [emailForOtp, setEmailForOtp] = useState(''); // Track the email input for OTP mode
//   const [administratorMode, setAdministratorMode] = useState(false); // Track if in administrator mode

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const email = event.target.email.value;
//     const password = event.target.password.value;

//     // Regular email/password login
//     if (!email || !password) {
//       if (!email) setEmailError(true);
//       if (!password) setPasswordError(true);
//       return;
//     }


//     const success = administratorMode ? await administratorLogin(email, password) : await loginByPassword(email, password);




//   };

//   const handleInputChange = (event) => {
//     const { name, value } = event.target;
//     if (name === 'email') {
//       setEmailError(false);
//     }
//     if (name === 'password') setPasswordError(false);
//   };

//   const toggleVisibility = () => {
//     setToggle(!toggle);
//   };

//   const switchToOTPMode = () => {
//     setOtpMode(true);
//     setEmailError(false); // Reset email error state
//     setPasswordError(false); // Reset password error state
//   };

//   const sendOTPHandler = async () => {
//     if (!emailForOtp) {
//       setEmailError(true);
//       return;
//     }
//     const success = await sendOTP(emailForOtp);

//     // if (success) {
//     //   toast.success("OTP sent successfully");
//     //   // Example: navigate('/otp-verification'); // Uncomment if redirecting after OTP
//     // } else {
//     //   toast.error("Failed to send OTP");
//     // }
//   };

//   const switchToAdministratorMode = () => {
//     setAdministratorMode(true);
//     setOtpMode(false); // Ensure OTP mode is disabled when switching to administrator mode
//   };

//   const switchToUserMode = () => {
//     setAdministratorMode(false);
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: '100vh' }}>
//         <CssBaseline />
//         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//           <Box
//             sx={{
//               my: 8,
//               mx: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             <Typography variant="h4" sx={{ mb: 2, color: "#2c2143" }}>
//               {otpMode ? "OTP Login" : administratorMode ? "Administrator Login" : "Login"}
//             </Typography>
//             <Typography variant="subtitle1" sx={{ mb: 1 }}>
//               {otpMode
//                 ? "Please enter your registered email to receive OTP."
//                 : administratorMode
//                   ? "Welcome! Please enter your details."
//                   : "Welcome back! Please enter your details."}
//             </Typography>
//             <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
//               {!otpMode && (
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="email"
//                   label={otpMode ? "Enter your registered email" : "Enter your email"}
//                   name="email"
//                   autoComplete="email"
//                   autoFocus
//                   error={emailError}
//                   helperText={emailError && 'Email is required'}
//                   onChange={handleInputChange}
//                 />
//               )}
//               {!otpMode && (
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   type={toggle ? 'text' : 'password'}
//                   id="password"
//                   autoComplete="current-password"
//                   error={passwordError}
//                   helperText={passwordError && 'Password is required'}
//                   onChange={handleInputChange}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton onClick={toggleVisibility}>
//                           {toggle ? (
//                             <Visibility />
//                           ) : (
//                             <VisibilityOff />
//                           )}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                 />
//               )}

//               {!otpMode && (
//                 <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
//                   <FormControlLabel
//                     control={<Checkbox value="remember" color="primary" />}
//                     label="Remember me"
//                   />
//                   {administratorMode ? (
//                     <StyledLink to="#" onClick={switchToUserMode}>
//                       Back to User Login
//                     </StyledLink>
//                   ) : (
//                     <StyledLink to="#">
//                       Forgot your password?
//                     </StyledLink>
//                   )}
//                 </Grid>
//               )}

//               {otpMode && (
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="emailForOtp"
//                   label="Enter your registered email"
//                   name="emailForOtp"
//                   autoComplete="email"
//                   autoFocus
//                   error={emailError}
//                   helperText={emailError && 'Email is required'}
//                   value={emailForOtp}
//                   onChange={(e) => setEmailForOtp(e.target.value)}
//                 />
//               )}
//               {otpMode && (
//                 <LightPurpleButton
//                   fullWidth
//                   variant="contained"
//                   sx={{ mt: 2 }}
//                   onClick={sendOTPHandler}
//                 >
//                   {loader ? <CircularProgress size={24} color="inherit" /> : "Send OTP"}
//                 </LightPurpleButton>
//               )}
//               {!otpMode && (
//                 <LightPurpleButton
//                   type="submit"
//                   fullWidth
//                   variant="contained"
//                   sx={{ mt: 3 }}
//                 >
//                   {loader ?
//                     <CircularProgress size={24} color="inherit" />
//                     : "Login"}
//                 </LightPurpleButton>
//               )}
//               {!otpMode && !administratorMode && (
//                 <Grid container sx={{ display: "flex", justifyContent: "space-between", marginTop: "7px" }}>
//                   <StyledLink to="#" onClick={switchToOTPMode}>
//                     Try another way to login?
//                   </StyledLink>
//                   <StyledAdministratorLink to="#" onClick={switchToAdministratorMode}>
//                     Administrator Mode
//                   </StyledAdministratorLink>;
//                 </Grid>
//               )}
//             </Box>
//           </Box>
//         </Grid>
//         <Grid
//           item
//           xs={false}
//           sm={4}
//           md={7}
//           sx={{
//             backgroundImage: `url(${bgpic})`,
//             backgroundRepeat: 'no-repeat',
//             backgroundColor: (t) =>
//               t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//           }}
//         />
//       </Grid>
//       <Backdrop
//         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={loader}
//       >
//         <CircularProgress color="primary" />
//         Please Wait
//       </Backdrop>
//     </ThemeProvider>
//   );
// }

// export default LoginPage;

// const StyledAdministratorLink = styled(Link)`
//     color: #C0392B; /* Set text color */
//     text-decoration: underline; /* Add underline */
// `;


import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  CircularProgress,
  Box,
  styled,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LightPurpleButton } from '../components/buttonStyles';
import { AuthContext } from './context/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Import background image (replace with your actual image import)
import bgpic from "../assets/designlogin.jpg";

const defaultTheme = createTheme();

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const LoginPage = () => {
  const { loginByPassword, sendOTP } = useContext(AuthContext);
  const navigate = useNavigate();

  const [toggle, setToggle] = useState(false);
 
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [otpMode, setOtpMode] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otpEmail, setOtpEmail] = useState('');
  const [emailForOtp, setEmailForOtp] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (otpMode) {
      // Handle OTP submission logic
      if (!emailForOtp) {
        setEmailError(true);
        return;
      }
      const success = await sendOTP(emailForOtp);

    } else {
      // Regular email/password login
      if (!email || !password) {
        if (!email) setEmailError(true);
        if (!password) setPasswordError(true);
        return;
      }
      
      const success = await loginByPassword(email, password);
      

    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
      setEmailError(false);
    }
    if (name === 'password') {
      setPassword(value);
      setPasswordError(false);
    }
    if (name === 'emailForOtp') {
      setEmailForOtp(value);
      setEmailError(false);
    }
  };

  const toggleVisibility = () => {
    setToggle(!toggle);
  };

  const switchToOTPMode = () => {
    setOtpMode(true);
    setEmailError(false); // Reset email error state
    setPasswordError(false); // Reset password error state
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h4" sx={{ mb: 2, color: "#2c2143" }}>
              {otpMode ? "OTP Login" : "User Login"}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {otpMode
                ? "Please enter your registered email to receive OTP."
                : "Welcome back! Please enter your details."}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              {!otpMode && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={emailError}
                  helperText={emailError && 'Email is required'}
                  value={email}
                  onChange={handleInputChange}
                />
              )}
              {!otpMode && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={toggle ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  error={passwordError}
                  helperText={passwordError && 'Password is required'}
                  value={password}
                  onChange={handleInputChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleVisibility}>
                          {toggle ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}

              {!otpMode && (
                <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <StyledLink to="#">
                    Forgot your password?
                  </StyledLink>
                </Grid>
              )}

              {otpMode && (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="emailForOtp"
                  label="Enter your registered email"
                  name="emailForOtp"
                  autoComplete="email"
                  autoFocus
                  error={emailError}
                  helperText={emailError && 'Email is required'}
                  value={emailForOtp}
                  onChange={handleInputChange}
                />
              )}
              {otpMode && (
                <LightPurpleButton
                  fullWidth
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={handleSubmit}
                >
                  Send OTP
                </LightPurpleButton>
              )}
              {!otpMode && (
                <LightPurpleButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                 
                    Login
                </LightPurpleButton>
              )}
              {!otpMode && (
                <Grid container sx={{ display: "flex", justifyContent: "space-between", marginTop: "7px" }}>
                  <StyledLink to="#" onClick={switchToOTPMode}>
                    Try another way to login?
                  </StyledLink>
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${bgpic})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default LoginPage;
