import React from "react";
import {
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts";

const assignmentAnalysisData = {
  totalAssignments: 20,
  completed: 15,
  notSubmittedOnTime: 5,
};

const attendanceData = [
  { month: "January", present: 20, absent: 5 },
  { month: "February", present: 18, absent: 7 },
  { month: "March", present: 22, absent: 3 },
  // Add more months as needed
];

const FacultyHomePage = () => {
  const { totalAssignments, completed, notSubmittedOnTime } = assignmentAnalysisData;

  const totalStudents = completed + notSubmittedOnTime;
  const completionPercentage = (completed / totalStudents) * 100;
  const notSubmittedPercentage = (notSubmittedOnTime / totalStudents) * 100;

  const pieChartData = [
    { name: "Completed", value: completed },
    { name: "Not Submitted on Time", value: notSubmittedOnTime },
  ];

  const COLORS = ["#0088FE", "#FF8042"];

  return (
    <div>
      <Typography variant="h5" gutterBottom align="center">
        Assignment and Attendance Analysis
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {/* Assignment Analysis */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" gutterBottom>
              Assignment Submission
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 400, height: 400 }}>
              <PieChart width={400} height={400}>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#8884d8"
                  label
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Box>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography variant="subtitle1">
                Completion Rate: {completionPercentage.toFixed(2)}%
              </Typography>
              <Typography variant="subtitle1">
                Not Submitted Rate: {notSubmittedPercentage.toFixed(2)}%
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Attendance Analysis */}
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Typography variant="h6" gutterBottom>
              Faculty Attendance (Month-wise)
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 400, height: 400 }}>
              <PieChart width={400} height={400}>
                <Pie
                  data={attendanceData}
                  dataKey="present"
                  nameKey="month"
                  cx="50%"
                  cy="50%"
                  outerRadius={150}
                  fill="#82ca9d"
                  label
                >
                  {attendanceData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </Box>
            <Box sx={{ textAlign: "center", mt: 2 }}>
              <Typography variant="subtitle1">
                Average Present Rate: {(attendanceData.reduce((acc, curr) => acc + curr.present, 0) / attendanceData.length).toFixed(2)}%
              </Typography>
              <Typography variant="subtitle1">
                Average Absent Rate: {(attendanceData.reduce((acc, curr) => acc + curr.absent, 0) / attendanceData.length).toFixed(2)}%
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default FacultyHomePage;
