import React, { useState } from 'react';
import { Typography, Button, FormControl, Select, MenuItem, Container, Grid, CircularProgress, Box } from '@mui/material';

const StudentCourses = ({ schoolId }) => {
  const [studentClass, setStudentClass] = useState('');
  const [optionalSubject, setOptionalSubject] = useState('');
  const [stream, setStream] = useState('');
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [coursesFetched, setCoursesFetched] = useState(false);

  const fetchCourses = async () => {
    if (!studentClass || (!optionalSubject && (studentClass === 'XI' || studentClass === 'XII')) || (!stream && (studentClass === 'XI' || studentClass === 'XII'))) {
      setError(new Error('Please fill in all input fields'));
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.isnartecherp.online/api/v1/getCourses?schoolId=${schoolId}&optionalSubject=${optionalSubject}&stream=${stream}&studentClass=${studentClass}`);
      if (!response.ok) {
        throw new Error('Failed to fetch courses');
      }
      const data = await response.json();
      setCourses(data.subjects);
      setCoursesFetched(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      {!coursesFetched ? (
        <Box>
          <Box sx={{ width: '100%', backgroundColor: '#3f51b5', borderRadius: '8px' }}>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              style={{
                fontWeight: 500,
                color: '#fff',
                padding: '8px 0'
              }}
            >
              Check Your Courses
            </Typography>
          </Box>
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <Select
              value={studentClass}
              onChange={(e) => setStudentClass(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Select Class</MenuItem>
              {["L.K.G", "U.K.G", "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"].map((item, index) => (
                <MenuItem key={index} value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {(studentClass === 'XI' || studentClass === 'XII') && (
            <>
              <Typography variant="h5" align="center" gutterBottom>Select Optional Subject</Typography>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <Select
                  value={optionalSubject}
                  onChange={(e) => setOptionalSubject(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">Select Optional Subject</MenuItem>
                  {["Physical Education", "Hindi", "Music"].map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h5" align="center" gutterBottom>Select Stream</Typography>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <Select
                  value={stream}
                  onChange={(e) => setStream(e.target.value)}
                  displayEmpty
                >
                  <MenuItem value="">Select Stream</MenuItem>
                  {["Mathematics", "Biology", "Commerce", "Humanities"].map((item, index) => (
                    <MenuItem key={index} value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              onClick={fetchCourses}
              color="primary"
              style={{
                width: 'fit-content',  // Adjusts width to fit content
                marginBottom: '20px',
                backgroundColor: '#3f51b5',
                color: '#ffffff',
                textTransform: 'none'  // Prevents uppercase transformation
              }}
            >
              Fetch Courses
            </Button>
          </div>

          {error && <Typography variant="body1" color="error" align="center">{error.message}</Typography>}
        </Box>
      ) : (
        <Box>
          <Button variant="contained" onClick={() => setCoursesFetched(false)} color="primary" fullWidth>Check For Other Class</Button>
          <Typography variant="h6" align="center" gutterBottom>Showing courses for Class: {studentClass}</Typography>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              {courses.map((course, index) => (
                <Box key={index} border={1} borderRadius={5} p={2} mb={2}>
                  <Typography variant="body1"><strong>Subject Code:</strong> {course.subjectCode}</Typography>
                  <Typography variant="body1"><strong>Subject Name:</strong> {course.subjectName}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
    </Container>
  );
};

export default StudentCourses;
