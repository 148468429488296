
import React, { useState, useContext } from 'react';
import { AppBar, Toolbar, Box, Button, Menu, MenuItem, ListItemText } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu'; // Import Menu icon
import Isnartechlogo from '../assets/newlogoisnar.png'; // Adjust path as needed
import { AuthContext } from './context/AuthContext';

const ErpNavbar = () => {
    const { isLoggedIn, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const [menuAnchorEl, setMenuAnchorEl] = useState(null); // State for menu anchor element
    const [loginSubMenuAnchorEl, setLoginSubMenuAnchorEl] = useState(null); // State for login submenu anchor element

    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget); // Open menu
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null); // Close menu
    };

    const handleLoginSubMenuOpen = (event) => {
        setLoginSubMenuAnchorEl(event.currentTarget); // Open login submenu
    };

    const handleLoginSubMenuClose = () => {
        setLoginSubMenuAnchorEl(null); // Close login submenu
    };

    const handleLoginLogout = () => {
        if (isLoggedIn) {
            logout();
        } else {
            navigate('/loginPage');
        }
    };

    const handleDashboardClick = () => {
        const userType = localStorage.getItem('userType');
        const schoolId = localStorage.getItem('schoolId');
        const _id = localStorage.getItem('_id');
        const fullName = localStorage.getItem('fullName');
        const standard = localStorage.getItem('standard');
        const section = localStorage.getItem('section');        
        const rollNumber = localStorage.getItem('rollNumber');  
        const schoolName = localStorage.getItem('schoolName');
        let classTeacherDetails = null;
        try {
            classTeacherDetails = JSON.parse(localStorage.getItem('classTeacherDetails')) || null;
        } catch (error) {
            console.error("Error parsing classTeacherDetails:", error);
        }
        // Fetch other user details as needed

        if (userType === 'admin') {
            navigate('/Admin/dashboard', { state: { userType, schoolId, _id, fullName} });
        } else if (userType === 'student') {
            // Navigate to Student Dashboard with relevant state
            navigate('/StudentDashboard', { state: { userType, schoolId, rollNumber, _id, standard, section, fullName } });
        } else if (userType === 'faculty') {
            // Navigate to Faculty Dashboard with relevant state
            navigate('/FacultyDashboard', { state: { userType, schoolId, classTeacherDetails, _id, fullName} });
        } else if (userType === 'staff') {
            // Navigate to Staff Dashboard with relevant state
            navigate('/StaffDashboard', { state: { userType, schoolId, _id, fullName} });
        }
    };

    return (
        <StyledAppBar position="fixed">
            <StyledToolbar>
                <StyledLogo src={Isnartechlogo} alt="Isnartech Logo" />

                {/* Menu Icon for small screens */}
                <MenuIconWrapper>
                    <MenuIcon onClick={handleMenuOpen} />
                    <Menu
                        anchorEl={menuAnchorEl}
                        open={Boolean(menuAnchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem component={RouterLink} to="/" onClick={handleMenuClose}>
                            <ListItemText primary="Home" />
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/" onClick={handleMenuClose}>
                            <ListItemText primary="About Us" />
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/contactForm" onClick={handleMenuClose}>
                            <ListItemText primary="Contact Us" />
                        </MenuItem>
                        <MenuItem component={RouterLink} to="/" onClick={handleMenuClose}>
                            <ListItemText primary="Facility" />
                        </MenuItem>
                        {isLoggedIn ? (
                            <>
                                <MenuItem onClick={handleDashboardClick}>
                                    <ListItemText primary="Dashboard" />
                                </MenuItem>
                                <MenuItem onClick={logout}>
                                    <ListItemText primary="Logout" />
                                </MenuItem>
                            </>
                        ) : (
                            <>
                                <MenuItem onClick={handleLoginSubMenuOpen}>
                                    <ListItemText primary="Login" />
                                </MenuItem>
                                <Menu
                                    anchorEl={loginSubMenuAnchorEl}
                                    open={Boolean(loginSubMenuAnchorEl)}
                                    onClose={handleLoginSubMenuClose}
                                >
                                    <MenuItem onClick={() => {
                                        handleLoginSubMenuClose();
                                        navigate('/loginPage', { state: { userType: 'user' } });
                                    }}>
                                        <ListItemText primary="Logged as User" />
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleLoginSubMenuClose();
                                        navigate('/loginPage', { state: { userType: 'admin' } });
                                    }}>
                                        <ListItemText primary="Logged as Administrator" />
                                    </MenuItem>
                                </Menu>
                            </>
                        )}
                    </Menu>
                </MenuIconWrapper>

                {/* Hide regular navbar options for smaller screens */}
                <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', flexGrow: 1, justifyContent: 'flex-end' }}>
                    <StyledButton component={RouterLink} to="/" color="inherit">
                        Home
                    </StyledButton>
                    <StyledButton component={RouterLink} to="/" color="inherit">
                        About Us
                    </StyledButton>
                    <StyledButton component={RouterLink} to="/contactForm" color="inherit">
                        Contact Us
                    </StyledButton>
                    <StyledButton component={RouterLink} to="/" color="inherit">
                        Facility
                    </StyledButton>
                    {isLoggedIn ? (
                        <>
                            <StyledButton color="inherit" onClick={handleDashboardClick}>
                                Dashboard
                            </StyledButton>
                            <StyledButton color="inherit" onClick={logout}>
                                Logout
                            </StyledButton>
                        </>
                    ) : (
                        <>
                        <MenuItem onClick={handleLoginSubMenuOpen}>
                            <ListItemText primary="LOGIN" />
                        </MenuItem>
                        <Menu
                            anchorEl={loginSubMenuAnchorEl}
                            open={Boolean(loginSubMenuAnchorEl)}
                            onClose={handleLoginSubMenuClose}
                        >
                            <MenuItem onClick={() => {
                                handleLoginSubMenuClose();
                                navigate('/loginPage');
                            }}>
                                <ListItemText primary="Login as User" />
                            </MenuItem>
                            <MenuItem onClick={() => {
                                handleLoginSubMenuClose();
                                navigate('/adminstratorLogin');
                            }}>
                                <ListItemText primary="Login as Administrator" />
                            </MenuItem>
                        </Menu>
                    </>
                    )}
                </Box>
            </StyledToolbar>
        </StyledAppBar>
    );
};

export default ErpNavbar;

const StyledAppBar = styled(AppBar)`
    background-color: #5c6bc0;
`;

const StyledToolbar = styled(Toolbar)`
    display: flex;
    justify-content: space-between;
`;

const StyledLogo = styled.img`
    width: 100px;
    height: auto;
    margin-right: 10px;
    filter: invert(1);
`;

const StyledButton = styled(Button)`
    text-transform: none;
    display: flex;
    align-items: center;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const MenuIconWrapper = styled.div`
    display: none; /* Hide by default for larger screens */
    @media (max-width: 600px) {
        display: flex; /* Display for smaller screens */
        align-items: center;
    }
`;
