// "use client";
// import React from "react";
// import { Button } from "./MovingBorder";

// const MovingBorderBox = ()=> {

//     console.log("box is showin");
//   return (
//     <div className=" flex flex-row gap-5 ">
        
//         <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Registration and login for students, teachers, admin, and staff
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Personalized dashboards for each user type.
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Attendance tracking
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//        Assignment management
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Quiz and exam management
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Salary and finance management
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Complaint management
//       </Button>
//       <Button
//         borderRadius="1.75rem"
//         className="bg-white dark:bg-slate-900 text-black dark:text-white border-neutral-200 dark:border-slate-800 "
//       >
//         Bus tracking
//       </Button>
     
//     </div>
//   );
// }
// export default MovingBorderBox;


import React from "react";
import { Button } from "./MovingBorder"; // Assuming Button component is correctly imported

const MovingBorderBox = () => {
  return (
    <div className="flex flex-wrap justify-center gap-4 p-4 md:p-8">
      {/* Each Button wrapped in a flex container to display three per row */}
      <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between w-full md:w-auto">
        {/* Button 1 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Registration and Login
        </Button>
        
        {/* Button 2 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Personalized Dashboards
        </Button>
        
        {/* Button 3 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Attendance Tracking
        </Button>
      </div>

      <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between w-full md:w-auto">
        {/* Button 4 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Assignment Management
        </Button>
        
        {/* Button 5 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Quiz and Exam Management
        </Button>
        
        {/* Button 6 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Salary and Finance Management
        </Button>
      </div>

      <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between w-full md:w-auto">
        {/* Button 7 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Complaint Management
        </Button>
        
        {/* Button 8 */}
        <Button
          borderRadius="1.75rem"
          className="bg-slate-200 dark:bg-slate-900 text-[18px] font-medium  text-black dark:text-white border-neutral-200 dark:border-slate-800 p-4 flex-1 mb-4 md:mb-0"
        >
          Bus Tracking
        </Button>
        
        {/* Add more buttons here if needed */}
      </div>
    </div>
  );
};

export default MovingBorderBox;

