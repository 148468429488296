


import React, { useState } from 'react';
import { Container, Typography, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button, Box, CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';
import StudentAssignment from './StudentAssignment';

const FacultyAssignment = ({ classTeacherDetails, schoolId, userId }) => {
  const [assignmentNumber, setAssignmentNumber] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');
  const [selectedStandard, setSelectedStandard] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showStudentAssignment, setShowStudentAssignment] = useState(false); // State to toggle StudentAssignment component

  console.log(classTeacherDetails);

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleUpload = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('assignmentNumber', assignmentNumber);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('deadline', deadline);
    formData.append('standard', selectedStandard);
    formData.append('section', selectedSection);
    formData.append('schoolId', schoolId);
    formData.append('userId', userId);

    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }

    try {
      const response = await fetch('https://api.isnartecherp.online/api/v1/localFileUpload', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      setLoading(false);
      if (result.success) {
        toast.success('Assignment uploaded successfully!');
        // Reset form fields
        setAssignmentNumber('');
        setTitle('');
        setDescription('');
        setDeadline('');
        setSelectedStandard('');
        setSelectedSection('');
        setFiles([]);
      } else {
        toast.error('Failed to upload assignment, check deadline and other details..');
      }
    } catch (error) {
      console.error('Error uploading assignment:', error);
      setLoading(false);
      alert('Error uploading assignment');
    }
  };

  const toggleStudentAssignment = () => {
    setShowStudentAssignment(!showStudentAssignment);

  };

  const toggleMode = () => {

    setShowStudentAssignment(!showStudentAssignment); // Ensure to hide StudentAssignment when switching modes
  };

  return (
    <Container maxWidth="md" style={{ padding: '20px' }}>
      <Typography variant="h5" align="center" style={{ backgroundColor: '#5C6BC0', color: '#FFFFFF', padding: '10px', borderRadius: '5px', marginBottom: '20px' }}>
        {showStudentAssignment ? 'Check Assignment' : 'Upload Assignment'}
      </Typography>
      <Grid item xs={12} style={{ textAlign: 'end', marginTop: '10px' }}>
        {showStudentAssignment ? (
          <Button variant="text" color="primary" onClick={toggleMode} style={{ textTransform: 'none', textDecoration: 'underline', fontSize: '18px' }}>
            Upload Assignment
          </Button>
        ) : (
          <Button variant="text" color="primary" onClick={toggleStudentAssignment} style={{ textTransform: 'none', textDecoration: 'underline', fontSize: '18px' }}>
            Check Assignment
          </Button>
        )}
      </Grid>

      <Grid container spacing={2}>
        {!showStudentAssignment ? (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Assignment Number"
                value={assignmentNumber}
                onChange={(e) => setAssignmentNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Deadline"
                type="date"
                value={deadline}
                onChange={(e) => setDeadline(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Select Standard</InputLabel>
                <Select
                  value={selectedStandard}
                  onChange={(e) => setSelectedStandard(e.target.value)}
                >
                  <MenuItem value="">{selectedStandard ? "Change Standard" : "Select Standard"}</MenuItem>
                  {classTeacherDetails && classTeacherDetails.map(({ standard }) => (
                    <MenuItem key={standard} value={standard}>{standard}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Select Section</InputLabel>
                <Select
                  value={selectedSection}
                  onChange={(e) => setSelectedSection(e.target.value)}
                >
                  <MenuItem value="">{selectedSection ? "Change Section" : "Select Section"}</MenuItem>
                  {classTeacherDetails && classTeacherDetails.map(({ section }) => (
                    <MenuItem key={section} value={section}>{section}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Button variant="contained" component="label">
                  Upload Files
                  <input type="file" hidden onChange={handleFileChange} multiple />
                </Button>
                {files.length > 0 && <Typography variant="body2" style={{ marginLeft: '10px', flexGrow: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{files.length} file(s) selected</Typography>}
              </Box>
            </Grid>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Button variant="contained" onClick={handleUpload} color="primary" style={{ backgroundColor: '#5C6BC0', color: '#FFFFFF' }} disabled={loading}>
                {loading ? <CircularProgress size={24} style={{ color: '#FFFFFF' }} /> : 'Submit Assignment'}
              </Button>
            </Grid>
          </>
        ) : (
          <StudentAssignment classTeacherDetails={classTeacherDetails} schoolId={schoolId} />
        )}

      </Grid>
    </Container>
  );
};

export default FacultyAssignment;
