

import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Grid,
} from '@mui/material';
import { Edit, Delete, SearchOffOutlined } from '@mui/icons-material';
import toast from 'react-hot-toast';

const AllFaculty = ({schoolId}) => {
    const [faculty, setFaculty] = useState([]);
    const [editingFaculty, setEditingFaculty] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        fetchFaculty();
    }, [schoolId]);

    const fetchFaculty = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getAllFaculty?schoolId=${schoolId}`);
            const data = await response.json();
            setFaculty(data.faculty);
        } catch (error) {
            console.error('Error fetching faculty:', error);
        }
    };

    const handleEditClick = (facultyMember) => {
        setEditingFaculty(facultyMember);
        setOpenEditDialog(true);
    };

    const handleEditDialogClose = () => {
        setEditingFaculty(null);
        setOpenEditDialog(false);
    };

    const handleDeleteClick = (facultyMember) => {
        setEditingFaculty(facultyMember);
        setOpenDeleteDialog(true);
    };

    const handleDeleteDialogClose = () => {
        setEditingFaculty(null);
        setOpenDeleteDialog(false);
    };

    const handleUpdateFaculty = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateFacultyById/${editingFaculty._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingFaculty),
            });
            if (response.ok) {
                console.log('Faculty member updated:', editingFaculty);
                setOpenEditDialog(false);
                setEditingFaculty(null);
                fetchFaculty(); // Refetch faculty after update
                toast.success('Faculty information updated successfully');
            } else {
                console.error('Failed to update faculty member:', response.statusText);
                toast.error('Failed to update faculty member');
            }
        } catch (error) {
            console.error('Error updating faculty member:', error);
            toast.error('Failed to update faculty member');
        }
    };

    const handleDeleteFaculty = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/deleteFacultyById/${editingFaculty._id}`, {
                method: 'PUT',
            });
            if (response.ok) {
                console.log('Faculty member deleted:', editingFaculty);
                setOpenDeleteDialog(false);
                setEditingFaculty(null);
                fetchFaculty(); // Refetch faculty after delete
                toast.success('Faculty member deleted successfully');
            } else {
                console.error('Failed to delete faculty member:', response.statusText);
                toast.error('Failed to delete faculty member');
            }
        } catch (error) {
            console.error('Error deleting faculty member:', error);
            toast.error('Failed to delete faculty member');
        }
    };

    const handleCloseDialogs = () => {
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
    };

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Faculty Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Assignments</TableCell>
                                    <TableCell>Class Teacher Details</TableCell>
                                    <TableCell>Subjects</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {faculty.map((facultyMember) => (
                                    <TableRow key={facultyMember._id}>
                                        <TableCell>{facultyMember.fullName}</TableCell>
                                        <TableCell>{facultyMember.email}</TableCell>
                                        <TableCell>
                                            {facultyMember.assignments &&
                                                facultyMember.assignments.map((assignment, index) => (
                                                    <div key={index}>
                                                        <p>
                                                            Standard: {assignment.standard}, Section: {assignment.section}
                                                        </p>
                                                    </div>
                                                ))}
                                        </TableCell>
                                        <TableCell>
                                            {facultyMember.classTeacherDetails &&
                                                facultyMember.classTeacherDetails.map((detail, index) => (
                                                    <div key={index}>
                                                        <p>
                                                            Standard: {detail.standard}, Section: {detail.section}
                                                        </p>
                                                    </div>
                                                ))}
                                        </TableCell>
                                        <TableCell>
                                            {facultyMember.assignSubject}
                                        </TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditClick(facultyMember)}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteClick(facultyMember)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
                <DialogTitle>Edit Faculty Member</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fullName"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={editingFaculty?.fullName}
                        onChange={(e) =>
                            setEditingFaculty({
                                ...editingFaculty,
                                fullName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editingFaculty?.email}
                        onChange={(e) =>
                            setEditingFaculty({
                                ...editingFaculty,
                                email: e.target.value,
                            })
                        }
                    />
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
                        Edit Assignments:
                    </Typography>
                    {editingFaculty &&
                        editingFaculty.assignments &&
                        editingFaculty.assignments.map((assignment, index) => (
                            <div key={index}>
                                <TextField
                                    margin="dense"
                                    id={`standard-${index}`}
                                    label="Standard"
                                    type="text"
                                    fullWidth
                                    value={assignment.standard}
                                    onChange={(e) =>
                                        setEditingFaculty((prev) => {
                                            const updatedAssignments = [...prev.assignments];
                                            updatedAssignments[index].standard = e.target.value;
                                            return { ...prev, assignments: updatedAssignments };
                                        })
                                    }
                                />
                                <TextField
                                    margin="dense"
                                    id={`section-${index}`}
                                    label="Section"
                                    type="text"
                                    fullWidth
                                    value={assignment.section}
                                    onChange={(e) =>
                                        setEditingFaculty((prev) => {
                                            const updatedAssignments = [...prev.assignments];
                                            updatedAssignments[index].section = e.target.value;
                                            return { ...prev, assignments: updatedAssignments };
                                        })
                                    }
                                />
                            </div>
                        ))}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
                        Edit Class Teacher Details:
                    </Typography>
                    {editingFaculty &&
                        editingFaculty.classTeacherDetails &&
                        editingFaculty.classTeacherDetails.map((detail, index) => (
                            <div key={index}>
                                <TextField
                                    margin="dense"
                                    id={`ct-standard-${index}`}
                                    label="Standard"
                                    type="text"
                                    fullWidth
                                    value={detail.standard}
                                    onChange={(e) =>
                                        setEditingFaculty((prev) => {
                                            const updatedDetails = [...prev.classTeacherDetails];
                                            updatedDetails[index].standard = e.target.value;
                                            return { ...prev, classTeacherDetails: updatedDetails };
                                        })
                                    }
                                />
                                <TextField
                                    margin="dense"
                                    id={`ct-section-${index}`}
                                    label="Section"
                                    type="text"
                                    fullWidth
                                    value={detail.section}
                                    onChange={(e) =>
                                        setEditingFaculty((prev) => {
                                            const updatedDetails = [...prev.classTeacherDetails];
                                            updatedDetails[index].section = e.target.value;
                                            return { ...prev, classTeacherDetails: updatedDetails };
                                        })
                                    }
                                />
                            </div>
                        ))}
                    <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
                        Edit Subjects:
                    </Typography>
                    {editingFaculty &&
                        editingFaculty.subjects &&
                        editingFaculty.subjects.map((subject, index) => (
                            <div key={index}>
                                <TextField
                                    margin="dense"
                                    id={`subject-${index}`}
                                    label="Subject"
                                    type="text"
                                    fullWidth
                                    value={subject}
                                    onChange={(e) =>
                                        setEditingFaculty((prev) => {
                                            const updatedSubjects = [...prev.subjects];
                                            updatedSubjects[index] = e.target.value;
                                            return { ...prev, subjects: updatedSubjects };
                                        })
                                    }
                                />
                            </div>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateFaculty} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Faculty Member</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this faculty member?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteFaculty} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AllFaculty;

// import React, { useState, useEffect } from 'react';
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     TableRow,
//     Paper,
//     Button,
//     IconButton,
//     Dialog,
//     DialogTitle,
//     DialogContent,
//     DialogActions,
//     TextField,
//     Typography,
//     Grid,
// } from '@mui/material';
// import { Edit, Delete } from '@mui/icons-material';
// import toast from 'react-hot-toast';

// const AllFaculty = () => {
//     const [faculty, setFaculty] = useState([]);
//     const [editingFaculty, setEditingFaculty] = useState(null);
//     const [openEditDialog, setOpenEditDialog] = useState(false);
//     const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

//     useEffect(() => {
//         fetchFaculty();
//     }, []);

//     const fetchFaculty = async () => {
//         try {
//             const response = await fetch('http://localhost:4000/api/v1/getAllFaculty');
//             const data = await response.json();
//             setFaculty(data.faculty);
//         } catch (error) {
//             console.error('Error fetching faculty:', error);
//         }
//     };

//     const handleEditClick = (facultyMember) => {
//         setEditingFaculty(facultyMember);
//         setOpenEditDialog(true);
//     };

//     const handleEditDialogClose = () => {
//         setEditingFaculty(null);
//         setOpenEditDialog(false);
//     };

//     const handleDeleteClick = (facultyMember) => {
//         setEditingFaculty(facultyMember);
//         setOpenDeleteDialog(true);
//     };

//     const handleDeleteDialogClose = () => {
//         setEditingFaculty(null);
//         setOpenDeleteDialog(false);
//     };

//     const handleUpdateFaculty = async () => {
//         try {
//             const response = await fetch(`http://localhost:4000/api/v1/updateFacultyById/${editingFaculty._id}`, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                 },
//                 body: JSON.stringify(editingFaculty),
//             });
//             if (response.ok) {
//                 console.log('Faculty member updated:', editingFaculty);
//                 setOpenEditDialog(false);
//                 setEditingFaculty(null);
//                 fetchFaculty(); // Refetch faculty after update
//                 toast.success('Faculty information updated successfully');
//             } else {
//                 console.error('Failed to update faculty member:', response.statusText);
//                 toast.error('Failed to update faculty member');
//             }
//         } catch (error) {
//             console.error('Error updating faculty member:', error);
//             toast.error('Failed to update faculty member');
//         }
//     };

//     const handleDeleteFaculty = async () => {
//         try {
//             const response = await fetch(`http://localhost:4000/api/v1/deleteFacultyById/${editingFaculty._id}`, {
//                 method: 'PUT',
//             });
//             if (response.ok) {
//                 console.log('Faculty member deleted:', editingFaculty);
//                 setOpenDeleteDialog(false);
//                 setEditingFaculty(null);
//                 fetchFaculty(); // Refetch faculty after delete
//                 toast.success('Faculty member deleted successfully');
//             } else {
//                 console.error('Failed to delete faculty member:', response.statusText);
//                 toast.error('Failed to delete faculty member');
//             }
//         } catch (error) {
//             console.error('Error deleting faculty member:', error);
//             toast.error('Failed to delete faculty member');
//         }
//     };

//     const handleCloseDialogs = () => {
//         setOpenEditDialog(false);
//         setOpenDeleteDialog(false);
//     };

//     return (
//         <div style={{ padding: 20 }}>
//             <Typography variant="h6" gutterBottom>
//                 Faculty Information
//             </Typography>
//             <Grid container spacing={3}>
//                 <Grid item xs={12}>
//                     <TableContainer component={Paper}>
//                         <Table>
//                             <TableHead>
//                                 <TableRow>
//                                     <TableCell>Full Name</TableCell>
//                                     <TableCell>Email</TableCell>
//                                     <TableCell>Assignments</TableCell>
//                                     <TableCell>Class Teacher Details</TableCell>
//                                     <TableCell>Subjects</TableCell>
//                                     <TableCell>Actions</TableCell>
//                                 </TableRow>
//                             </TableHead>
//                             <TableBody>
//                                 {faculty.map((facultyMember) => (
//                                     <TableRow key={facultyMember._id}>
//                                         <TableCell>{facultyMember.fullName}</TableCell>
//                                         <TableCell>{facultyMember.email}</TableCell>
//                                         <TableCell>
//                                             {facultyMember.assignments &&
//                                                 facultyMember.assignments.map((assignment, index) => (
//                                                     <div key={index}>
//                                                         <p>
//                                                             Standard: {assignment.standard}, Section: {assignment.section}
//                                                         </p>
//                                                     </div>
//                                                 ))}
//                                         </TableCell>
//                                         <TableCell>
//                                             {facultyMember.classTeacherDetails &&
//                                                 facultyMember.classTeacherDetails.map((detail, index) => (
//                                                     <div key={index}>
//                                                         <p>
//                                                             Standard: {detail.standard}, Section: {detail.section}
//                                                         </p>
//                                                     </div>
//                                                 ))}
//                                         </TableCell>
//                                         <TableCell>
//                                              {facultyMember.assignSubject}
//                                         </TableCell>
//                                         <TableCell>
//                                             <IconButton
//                                                 color="primary"
//                                                 onClick={() => handleEditClick(facultyMember)}
//                                             >
//                                                 <Edit />
//                                             </IconButton>
//                                             <IconButton
//                                                 color="secondary"
//                                                 onClick={() => handleDeleteClick(facultyMember)}
//                                             >
//                                                 <Delete />
//                                             </IconButton>
//                                         </TableCell>
//                                     </TableRow>
//                                 ))}
//                             </TableBody>
//                         </Table>
//                     </TableContainer>
//                 </Grid>
//             </Grid>

//             {/* Edit Dialog */}
//             <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
//                 <DialogTitle>Edit Faculty Member</DialogTitle>
//                 <DialogContent>
//                     <TextField
//                         autoFocus
//                         margin="dense"
//                         id="fullName"
//                         label="Full Name"
//                         type="text"
//                         fullWidth
//                         value={editingFaculty?.fullName}
//                         onChange={(e) =>
//                             setEditingFaculty({
//                                 ...editingFaculty,
//                                 fullName: e.target.value,
//                             })
//                         }
//                     />
//                     <TextField
//                         margin="dense"
//                         id="email"
//                         label="Email"
//                         type="email"
//                         fullWidth
//                         value={editingFaculty?.email}
//                         onChange={(e) =>
//                             setEditingFaculty({
//                                 ...editingFaculty,
//                                 email: e.target.value,
//                             })
//                         }
//                     />
//                     <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
//                         Edit Assignments:
//                     </Typography>
//                     {editingFaculty &&
//                         editingFaculty.assignments &&
//                         editingFaculty.assignments.map((assignment, index) => (
//                             <div key={index}>
//                                 <TextField
//                                     margin="dense"
//                                     id={`standard-${index}`}
//                                     label="Standard"
//                                     type="text"
//                                     fullWidth
//                                     value={assignment.standard}
//                                     onChange={(e) =>
//                                         setEditingFaculty((prev) => {
//                                             const updatedAssignments = [...prev.assignments];
//                                             updatedAssignments[index].standard = e.target.value;
//                                             return { ...prev, assignments: updatedAssignments };
//                                         })
//                                     }
//                                 />
//                                 <TextField
//                                     margin="dense"
//                                     id={`section-${index}`}
//                                     label="Section"
//                                     type="text"
//                                     fullWidth
//                                     value={assignment.section}
//                                     onChange={(e) =>
//                                         setEditingFaculty((prev) => {
//                                             const updatedAssignments = [...prev.assignments];
//                                             updatedAssignments[index].section = e.target.value;
//                                             return { ...prev, assignments: updatedAssignments };
//                                         })
//                                     }
//                                 />
//                             </div>
//                         ))}
//                     <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
//                         Edit Class Teacher Details:
//                     </Typography>
//                     {editingFaculty &&
//                         editingFaculty.classTeacherDetails &&
//                         editingFaculty.classTeacherDetails.map((detail, index) => (
//                             <div key={index}>
//                                 <TextField
//                                     margin="dense"
//                                     id={`ct-standard-${index}`}
//                                     label="Standard"
//                                     type="text"
//                                     fullWidth
//                                     value={detail.standard}
//                                     onChange={(e) =>
//                                         setEditingFaculty((prev) => {
//                                             const updatedDetails = [...prev.classTeacherDetails];
//                                             updatedDetails[index].standard = e.target.value;
//                                             return { ...prev, classTeacherDetails: updatedDetails };
//                                         })
//                                     }
//                                 />
//                                 <TextField
//                                     margin="dense"
//                                     id={`ct-section-${index}`}
//                                     label="Section"
//                                     type="text"
//                                     fullWidth
//                                     value={detail.section}
//                                     onChange={(e) =>
//                                         setEditingFaculty((prev) => {
//                                             const updatedDetails = [...prev.classTeacherDetails];
//                                             updatedDetails[index].section = e.target.value;
//                                             return { ...prev, classTeacherDetails: updatedDetails };
//                                         })
//                                     }
//                                 />
//                             </div>
//                         ))}
//                     <Typography variant="subtitle1" gutterBottom style={{ marginTop: 10 }}>
//                         Edit Subjects:
//                     </Typography>
//                     {editingFaculty &&
//                         editingFaculty.subjects &&
//                         editingFaculty.subjects.map((subject, index) => (
//                             <div key={index}>
//                                 <TextField
//                                     margin="dense"
//                                     id={`subject-${index}`}
//                                     label="Subject"
//                                     type="text"
//                                     fullWidth
//                                     value={subject}
//                                     onChange={(e) =>
//                                         setEditingFaculty((prev) => {
//                                             const updatedSubjects = [...prev.subjects];
//                                             updatedSubjects[index] = e.target.value;
//                                             return { ...prev, subjects: updatedSubjects };
//                                         })
//                                     }
//                                 />
//                             </div>
//                         ))}
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleEditDialogClose} color="primary">
//                         Cancel
//                     </Button>
//                     <Button onClick={handleUpdateFaculty} color="primary">
//                         Save
//                     </Button>
//                 </DialogActions>
//             </Dialog>

//             {/* Delete Confirmation Dialog */}
//             <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
//                 <DialogTitle>Delete Faculty Member</DialogTitle>
//                 <DialogContent>
//                     <Typography variant="body1">
//                         Are you sure you want to delete this faculty member?
//                     </Typography>
//                 </DialogContent>
//                 <DialogActions>
//                     <Button onClick={handleDeleteDialogClose} color="primary">
//                         Cancel
//                     </Button>
//                     <Button onClick={handleDeleteFaculty} color="secondary">
//                         Delete
//                     </Button>
//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// };

// export default AllFaculty;
