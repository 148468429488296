

import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    CssBaseline,
    Box,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { AppBar, Drawer } from '../../components/styles';
import SideBar from './Sidebar';
import HomePage from './HomePage';
import Logout from '../Logout';
import AllStudents from '../admin/AllStudents'
import AllFaculty from '../admin/AllFaculty';
import AllStaff from '../admin/AllStaff';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import AdminEnrollment from './AdminEnroll';
import Profile from './Profile';
import AddSubjects from './AddSubjects';
import FacultyEnrollment from './FacultyEnrollment';
import StaffEnrollment from './StaffEnrollment';
import StudentEnrollment from './StudentEnrollment';
import AllAdmin from './AllAdmin';
import AdminLeave from './AdminLeaveRequest';
import StudentLeave from '../Faculty/StudentLeave';
import FacultyLeave from '../admin/FacultyLeave';
import StaffLeave from '../admin/StaffLeave';

const Dashboard = () => {
    const location = useLocation();
    const schoolId = location.state?.schoolId;



    const [open, setOpen] = useState(false);
    const [selectedPage, setSelectedPage] = useState('Home');

    console.log(schoolId);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const renderContent = () => {
        switch (selectedPage) {
            case 'Home':
                return <HomePage schoolId={schoolId} />;
            case 'Admin':
                return <AdminEnrollment schoolId={schoolId} />;
            case 'Subjects':
                return <AddSubjects schoolId={schoolId} />;
            case 'Teachers':
                return <FacultyEnrollment schoolId={schoolId} />;
            case 'Students':
                return <StudentEnrollment schoolId={schoolId} selectedPage={selectedPage} />;
            case 'Staff':
                return <StaffEnrollment schoolId={schoolId} />;
            case 'AdminLeave':
                return <AdminLeave schoolId={schoolId} />;

            case 'ViewStudents':
                return <AllStudents schoolId={schoolId} />;
            case 'ViewTeachers':
                return <AllFaculty schoolId={schoolId} />;
            case 'ViewStaff':
                return <AllStaff schoolId={schoolId} />;
            case 'ViewAdmin':
                return <AllAdmin schoolId={schoolId} />;

            case 'ViewStudentsLeaveReq':
                return <StudentLeave schoolId={schoolId} />;

            case 'ViewTeachersLeaveReq':
                return <FacultyLeave schoolId={schoolId} />;

            case 'ViewStaffsLeaveReq':
                return <StaffLeave schoolId={schoolId} />;


            case 'AdminProfile':
                return <Profile />;
            case 'Logout':
                return <Logout />;
            default:
                return <HomePage />;
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar open={open} position='absolute'>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Adminstrator Dashboard
                    </Typography>
                    <IconButton
                        color="inherit"
                        component={Link}
                        to="/firstPage"
                        sx={{
                            marginLeft: 'auto', // This will push the button to the right end
                            textDecoration: 'none', // Ensure no underline
                        }}
                    >
                        <HomeIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open} sx={open ? styles.drawerStyled : styles.hideDrawer}>
                <Toolbar sx={styles.toolBarStyled}>
                    <IconButton onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav">
                    <SideBar setSelectedPage={setSelectedPage} selectedPage={selectedPage} />
                </List>
            </Drawer>
            <Box component="main" sx={styles.boxStyled}>
                <Toolbar />
                {renderContent()}
            </Box>
        </Box>
    );
}

export default Dashboard

const styles = {
    boxStyled: {
        backgroundColor: (theme) =>
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        padding: '20px'
    },
    toolBarStyled: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        px: [1],
    },
    drawerStyled: {
        display: "flex"
    },
    hideDrawer: {
        display: 'flex',
        '@media (max-width: 600px)': {
            display: 'none',
        },
    },
}
