


import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Grid,
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import toast from "react-hot-toast";

const AllStudents = ({ schoolId }) => {
    const [students, setStudents] = useState([]);
    const [editingStudent, setEditingStudent] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        fetchStudents();
    }, [schoolId]);

    const fetchStudents = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/getAllStudents?schoolId=${schoolId}`);
            const data = await response.json();
            setStudents(data.students);
        } catch (error) {
            console.error('Error fetching students:', error);
        }
    };

    const handleEditClick = (student) => {
        setEditingStudent(student);
        setOpenEditDialog(true);
    };

    const handleEditDialogClose = () => {
        setEditingStudent(null);
        setOpenEditDialog(false);
    };

    const handleDeleteClick = (student) => {
        setEditingStudent(student);
        setOpenDeleteDialog(true);
    };

    const handleDeleteDialogClose = () => {
        setEditingStudent(null);
        setOpenDeleteDialog(false);
    };

    const handleUpdateStudent = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/updateStudentById/${editingStudent._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingStudent),
            });
            if (response.ok) {
                console.log('Student updated:', editingStudent);
                setOpenEditDialog(false);
                setEditingStudent(null);
                fetchStudents(); // Refetch students after update
                toast.success("Student information updated successfully");
            } else {
                console.error('Failed to update student:', response.statusText);
                toast.error('Failed to update student');
            }
        } catch (error) {
            console.error('Error updating student:', error);
            toast.error('Failed to update student');
        }
    };

    const handleDeleteStudent = async () => {
        try {
            const response = await fetch(`https://api.isnartecherp.online/api/v1/deleteStudentById/${editingStudent._id}`, {
                method: 'PUT', 
            });
            if (response.ok) {
                console.log('Student deleted:', editingStudent);
                setOpenDeleteDialog(false);
                setEditingStudent(null);
                fetchStudents(); // Refetch students after delete
                toast.success("Student information deleted successfully");
            } else {
                console.error('Failed to delete student:', response.statusText);
                toast.error('Failed to delete student');
            }
        } catch (error) {
            console.error('Error deleting student:', error);
            toast.error('Failed to delete student');
        }
    };

    const handleCloseDialogs = () => {
        setOpenEditDialog(false);
        setOpenDeleteDialog(false);
    };

    return (
        <div style={{ padding: 20 }}>
            <Typography variant="h6" gutterBottom>
                Students Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Full Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Standard</TableCell>
                                    <TableCell>Section</TableCell>
                                    <TableCell>Roll Number</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {students.map((student) => (
                                    <TableRow key={student._id}>
                                        <TableCell>{student.fullName}</TableCell>
                                        <TableCell>{student.email}</TableCell>
                                        <TableCell>{student.standard}</TableCell>
                                        <TableCell>{student.section}</TableCell>
                                        <TableCell>{student.rollNumber}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleEditClick(student)}
                                            >
                                                <Edit />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteClick(student)}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* Edit Dialog */}
            <Dialog open={openEditDialog} onClose={handleEditDialogClose}>
                <DialogTitle>Edit Student</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="fullName"
                        label="Full Name"
                        type="text"
                        fullWidth
                        value={editingStudent?.fullName}
                        onChange={(e) =>
                            setEditingStudent({
                                ...editingStudent,
                                fullName: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        value={editingStudent?.email}
                        onChange={(e) =>
                            setEditingStudent({
                                ...editingStudent,
                                email: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="standard"
                        label="Standard"
                        type="text"
                        fullWidth
                        value={editingStudent?.standard}
                        onChange={(e) =>
                            setEditingStudent({
                                ...editingStudent,
                                standard: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="section"
                        label="Section"
                        type="text"
                        fullWidth
                        value={editingStudent?.section}
                        onChange={(e) =>
                            setEditingStudent({
                                ...editingStudent,
                                section: e.target.value,
                            })
                        }
                    />
                    <TextField
                        margin="dense"
                        id="rollNumber"
                        label="Roll Number"
                        type="text"
                        fullWidth
                        value={editingStudent?.rollNumber}
                        onChange={(e) =>
                            setEditingStudent({
                                ...editingStudent,
                                rollNumber: e.target.value,
                            })
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateStudent} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                <DialogTitle>Delete Student</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you want to delete this student?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteStudent} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AllStudents;

